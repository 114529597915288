
import React, { useContext, useState } from "react";
import styled from "styled-components";
import { Input, Button, NextUIProvider } from "@nextui-org/react";
import HeaderImage from "../../assets/img/intro-mobile.png";
// Assets
import ContactImg1 from "../../assets/img/contact-1.png";
import ContactImg2 from "../../assets/img/contact-2.png";
import ContactImg3 from "../../assets/img/contact-3.png";
import ipaddress from "../ipaddress";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import AuthContext from "../AuthProvider";
import { toast } from "react-toastify";


export default function Contact() {
  const {
    jwtToken,
    userId,
    username,
    branchId,
    companyid,
    role,
    companyname,
    branchname,
    autoid,
    isAuthenticated,
    login,
    logout,
  } = useContext(AuthContext) || {};

  const navigate = useNavigate();
  const [user, setuser] = useState({
    firstName: "",
    user_Email: "",
    subject: "",
    message: "",
    companyName: "",
    address: "",
    pinCode: "",
    city: "",
    state: "",
    gstNo: "",
    panNo: "",
    tanNo: "",
    mobile: "",
user_Name: "",
    personDesignation: "",
    user_Password:"",
    file: null,
  });

  const [registrationSuccess, setRegistrationSuccess] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setuser({
      ...user,
      [name]: value,
    });
    setErrors('');
  };
  const validateGST = (value) => {
    // GSTIN format validation regex
    const gstRegex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/;
  
    if (!value.match(gstRegex)) {
      return "Enter a valid GST number";
    }
    return "";
  };
  
  const validatePAN = (value) => {
    const panPattern = /^[A-Za-z]{5}\d{4}[A-Za-z]{1}$/;
    if (!value.match(panPattern)) {
      return "Enter a valid PAN number";
    }
    return "";
  };
  
  const validateMobile = (value) => {
    const mobilePattern = /^[6-9]\d{9}$/; // Validates Indian mobile numbers starting with 6-9 and having 10 digits
    if (!value.match(mobilePattern)) {
      return "Enter a valid mobile number";
    }
    return "";
  };
  
  const validateEmail = (value) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!value.match(emailPattern)) {
      return "Enter a valid email address";
    }
    return "";
  };

const handleImageChange = (e) => {
  const imagePath = e.target.files[0];
  if (imagePath) {
      // Validate if the selected file is an image
      const validFileTypes = ['image/', 'application/pdf'];
      if (!validFileTypes.some((type) => imagePath.type.startsWith(type))) {
        alert('Please select an image or PDF file.');
        return;
      }
      // Read the file and set it as the image path
      const reader = new FileReader();
      reader.onload = (event) => {
        setuser((prevEmployee) => ({
              ...prevEmployee,
              file: event.target.result
          }));
      };
      reader.readAsDataURL(imagePath);
  }
};


const [errors, setErrors] = useState({});
const validateForm = () => {
  const newErrors = {};
  if (!user.companyName) newErrors.companyName = 'Company Name is required';
  if (!user.user_Password) newErrors.User_Password = 'Password is required';
  if (!user.address) newErrors.address = 'Address is required';
  if (!user.pinCode) newErrors.pinCode = 'Pin Code is required';
  if (!user.city) newErrors.city = 'City is required';
  if (!user.state) newErrors.state = 'State is required';
  if (!user.gstNo) newErrors.gstNo = 'GST No is required';
  if (!user.panNo) newErrors.panNo = 'PAN No is required';
  if (!user.mobile) newErrors.mobile = 'Contact No is required';
  if (!user.user_Name) newErrors.User_Name = 'Person Name is required';
  if (!user.user_Email
    ) newErrors.User_Email = 'Email is required';
  if (!user.file) newErrors.file = 'File Upload is required';
  return newErrors;
};
  const handleSubmit = async (e) => {
    e.preventDefault();

    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      Swal.fire({
        icon: 'success',
        title: 'Form Validation Error',
        text: 'Please fill in all required fields.',
        customClass: {
          popup: 'swal2-popup',
          title: 'swal2-title',
          content: 'swal2-content',
          confirmButton: 'swal2-confirm',
          cancelButton: 'swal2-cancel'
        },
      });
      return;
    }
    const panError = validatePAN(user.panNo);
    const gstError = validateGST(user.gstNo);
    const mobileError = validateMobile(user.mobile);
    const emailError = validateEmail(user.user_Email);
  
    if (panError || gstError) {
      setErrors({
        ...errors,
        panNo: panError,
        gstNo: gstError,
        mobile: mobileError,
        user_Email: emailError,
      });
      toast.error('Please fill in all required fields and ensure GST, PAN, Mobile, and Email numbers are valid.', {
        position: 'top-center',
        autoClose: 900,
      });
      return;
    }
    console.log("user",user);
    const companyId = 'C00001';
  const branchId = 'B00001';
  const flag = 'add';
    try {
            const response = await fetch(`${ipaddress}user/saveUser?cid=${companyId}&bid=${branchId}&flag=${flag}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(user),
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();
      console.log(result);
      Swal.fire({
        icon: 'success',
        title: 'Registration Successful!',
        text: 'Congratulations! You have registered successfully.',
        customClass: {
          popup: 'swal2-popup',
          title: 'swal2-title',
          content: 'swal2-content',
          confirmButton: 'swal2-confirm',
          cancelButton: 'swal2-cancel'
        }
      });
      navigate('/login');
      setuser('');
      setErrors('');
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Registration Failed',
        text: 'There was an error while registering. Please try again later.',
        customClass: {
          popup: 'swal2-popup',
          title: 'swal2-title',
          content: 'swal2-content',
          confirmButton: 'swal2-confirm',
          cancelButton: 'swal2-cancel'
        }
      });
      console.error('There was a problem with the fetch operation:', error);
    }
  };
  return (
     <>
     {!username && (
  <Wrapper id="contact">
  <div className="lightBg">
    <div className="container">
      <HeaderInfo>
        <h1 className="font40 extraBold">Let's Register</h1>
        <p className="font13">
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut
          <br />
          labore et dolore magna aliquyam erat, sed diam voluptua.
        </p>
      </HeaderInfo>
      <GridContainer className="row" style={{ paddingBottom: "30px" }}>
<FormContainer className="col-xs-12 col-sm-12 col-md-8 col-lg-8">
<Form >
  <Row>
    <InputContainer>
      <label className="font13">Company Name</label>&nbsp;<span style={{ color: 'red' }}>*&nbsp;</span>:
      <Input type="text" name="companyName" value={user.companyName} onChange={handleChange} />
      {errors.companyName && <span style={{ color: 'red' }}>{errors.companyName}</span>}
    </InputContainer>
    <InputContainer>
      <label className="font13">Address</label>&nbsp;<span style={{ color: 'red' }}>*&nbsp;</span>:
      <Input type="text" name="address" value={user.address} onChange={handleChange} />
      {errors.address && <span style={{ color: 'red' }}>{errors.address}</span>}
    </InputContainer>
    <InputContainer>
      <label className="font13">Pin Code</label>&nbsp;<span style={{ color: 'red' }}>*&nbsp;</span>:
      <Input type="text" name="pinCode" value={user.pinCode} onChange={handleChange} />
      {errors.pinCode && <span style={{ color: 'red' }}>{errors.pinCode}</span>}
    </InputContainer>
  </Row>

  <Row>
    <InputContainer>
      <label className="font13">City</label>&nbsp;<span style={{ color: 'red' }}>*&nbsp;</span>:
      <Input type="text" name="city" value={user.city} onChange={handleChange} />
      {errors.city && <span style={{ color: 'red' }}>{errors.city}</span>}
    </InputContainer>
    <InputContainer>
      <label className="font13">State</label>&nbsp;<span style={{ color: 'red' }}>*&nbsp;</span>:
      <Input type="text" name="state" value={user.state} onChange={handleChange} />
      {errors.state && <span style={{ color: 'red' }}>{errors.state}</span>}
    </InputContainer>
    <InputContainer>
      <label className="font13">GST No</label>&nbsp;<span style={{ color: 'red' }}>*&nbsp;</span>:
      <Input type="text" name="gstNo" value={user.gstNo} onChange={handleChange} />
      {errors.gstNo && <span style={{ color: 'red' }}>{errors.gstNo}</span>}
    </InputContainer>
  </Row>

  <Row>
    <InputContainer>
      <label className="font13">PAN No</label>&nbsp;<span style={{ color: 'red' }}>*&nbsp;</span>:
      <Input type="text" name="panNo" value={user.panNo} onChange={handleChange} />
      {errors.panNo && <span style={{ color: 'red' }}>{errors.panNo}</span>}
    </InputContainer>
    <InputContainer>
      <label className="font13">TAN No </label>:
      <Input type="text" name="tanNo" value={user.tanNo} onChange={handleChange} />
    </InputContainer>
    <InputContainer>
      <label className="font13">Contact No</label>&nbsp;<span style={{ color: 'red' }}>*&nbsp;</span>:
      <Input type="text" name="mobile" value={user.mobile} onChange={handleChange} />
      {errors.mobile && <span style={{ color: 'red' }}>{errors.mobile}</span>}
    </InputContainer>
  </Row>

  <Row>
    <InputContainer>
      <label className="font13">Person Name</label>&nbsp;<span style={{ color: 'red' }}>*&nbsp;</span>:
      <Input type="text" name="user_Name" value={user.user_Name} onChange={handleChange} />
      {errors.User_Name && <span style={{ color: 'red' }}>{errors.User_Name}</span>}
    </InputContainer>
    <InputContainer>
      <label className="font13">Person Designation</label>:
      <Input type="text" name="personDesignation" value={user.personDesignation} onChange={handleChange} />
    </InputContainer>
    <InputContainer>
      <label className="font13">Email</label>&nbsp;<span style={{ color: 'red' }}>*&nbsp;</span>:
      <Input type="email" name="user_Email" value={user.user_Email} onChange={handleChange} />
      {errors.User_Email && <span style={{ color: 'red' }}>{errors.User_Email}</span>}
    </InputContainer>
  </Row>

  <Row>
    <InputContainer>
      <label className="font13">File Upload</label>&nbsp;<span style={{ color: 'red' }}>*&nbsp;</span>:
      <Input type="file" name="file" accept="image/*,application/pdf" onChange={handleImageChange} />
      {errors.file && <span style={{ color: 'red' }}>{errors.file}</span>}
    </InputContainer>

    <InputContainer>
      <label className="font13">Password</label>&nbsp;<span style={{ color: 'red' }}>*&nbsp;</span>:
      <Input type="password" name="user_Password" value={user.user_Password} onChange={handleChange} />
      {errors.User_Password && <span style={{ color: 'red' }}>{errors.User_Password}</span>}
    </InputContainer>
  </Row>

  <SumbitWrapper className="flex">
    <button type="submit" className="pointer animate radius8" style={{ maxWidth: "220px" }} onClick={handleSubmit}>
      Register
    </button>
  </SumbitWrapper>
</Form>
</FormContainer>

        <ImageContainer className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
        <img src={HeaderImage} alt="Company" className="company-image" />
        </ImageContainer>
      </GridContainer>
    </div>
  </div>
</Wrapper>
     )}
    
    </>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;

const HeaderInfo = styled.div`
  padding: 70px 0 30px 0;
  @media (max-width: 860px) {
    text-align: center;
  }
`;

const GridContainer = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;

const FormContainer = styled.div`
  padding-right: 15px;
`;

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 15px;
`;

const Form = styled.form`
  padding: 70px 0 30px 0;
  input,
  textarea {
    width: 100%;
    background-color: transparent;
    border: 0px;
    outline: none;
    box-shadow: none;
    border-bottom: 1px solid #707070;
    height: 30px;
    margin-bottom: 30px;
  }
  textarea {
    min-height: 100px;
  }
  @media (max-width: 860px) {
    padding: 30px 0;
  }
`;

const SumbitWrapper = styled.div`
  @media (max-width: 991px) {
    width: 100%;
    margin-bottom: 50px;
    margin-top:0;
  }
  button {
    border-radius: 20px;
    border: 1px solid #FF4B2B;
    background-color: #FF4B2B;
    color: #FFFFFF;
    font-size: 12px;
    font-weight: bold;
    padding: 12px 40px;
    letter-spacing: 1px;
    text-transform: uppercase;
    transition: transform 80ms ease-in;
  }

  button:active {
    transform: scale(0.95);
  }

  button:focus {
    outline: none;
  }

  button.ghost {
    background-color: transparent;
    border-color: #FFFFFF;
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
`;

const InputContainer = styled.div`
  flex: 1;
  margin-right: 10px;
`;


