import axios from 'axios';
import AuthContext from '../AuthProvider';
import { useContext } from 'react';
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';

let isSessionExpired = false; // Module-level variable to track session expiry

const useAxios = () => {
  const {
    jwtToken,
    logout
  } = useContext(AuthContext);
  const navigate = useNavigate();
  const axiosInstance = axios.create({
     baseURL: 'https://rapportsoft.in:8443/saas', // Your API base URL

    // baseURL: 'http://192.168.1.129:8080', // Your API base URL
  });

  // Add Authorization header to all requests
  axiosInstance.interceptors.request.use(
    config => {
      if (jwtToken) {
        config.headers['Authorization'] = `Bearer ${jwtToken}`;
      }
      return config;
    },
    error => Promise.reject(error)
  );

  // Response interceptor to handle 401 errors
  axiosInstance.interceptors.response.use(
    response => response,
    error => {
      if (error.response && error.response.status === 401) {
        if (!isSessionExpired) {
          isSessionExpired = true;
          logout();
          navigate("/logIn");
          toast.error("Session expired. Please log in again.", {
            autoClose: 1000,
            onClose: () => { 
              isSessionExpired = false; // Reset the flag when the toast is closed
            }
          });
        }
      }
      return Promise.reject(error);
    }
  );

  return axiosInstance;
};

export default useAxios;
