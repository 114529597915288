import React from 'react';
import { FaMoneyCheckAlt, FaTimesCircle, FaExchangeAlt, FaShieldAlt, FaShippingFast, FaUndoAlt, FaHeadset, FaLock, FaCloud } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import RapportSoftlogo from "../../assets/img/image copy.png";
export default function CancelationAndRefund() {
    return (
        <>
        <div className="topBar" style={{ paddingTop: 27 }}>
        <div className="logo">
            <Link to="/" >
          <img src={RapportSoftlogo} style={{ width: '180px' }} alt="logo" /></Link>
        </div>
        <hr className="separatorLine" />
        <nav className="navBar">
          <Link to="/" style={{ float: 'right' }}>Home</Link>
        </nav>
      </div>
      <div className="policy-container">
            <h1 className="policy-title">Our Policies</h1>

            <div className="policy-section">
                <FaMoneyCheckAlt className="policy-icon" />
                <h2 className="policy-heading">Refund Policy</h2>
                <p className="policy-text">
                    We offer a full refund within 30 days of your subscription purchase if you're not satisfied with our service. Please contact our support team to initiate the refund process.
                </p>
            </div>

            <div className="policy-section">
                <FaTimesCircle className="policy-icon" />
                <h2 className="policy-heading">Cancellation Policy</h2>
                <p className="policy-text">
                    You can cancel your subscription at any time. Your access to the service will continue until the end of the current billing cycle, after which it will be terminated.
                </p>
            </div>

            <div className="policy-section">
                <FaExchangeAlt className="policy-icon" />
                <h2 className="policy-heading">Upgrade/Downgrade Policy</h2>
                <p className="policy-text">
                    You can upgrade or downgrade your subscription plan at any time. Changes will take effect immediately, and any difference in cost will be adjusted in your next billing cycle.
                </p>
            </div>

            <div className="policy-section">
                <FaShieldAlt className="policy-icon" />
                <h2 className="policy-heading">Privacy Policy</h2>
                <p className="policy-text">
                    We are committed to protecting your privacy. All personal data collected is used solely for providing and improving our service. We will never share your data with third parties without your consent.
                </p>
            </div>

            <div className="policy-section">
                <FaCloud className="policy-icon" />
                <h2 className="policy-heading">Data Retention Policy</h2>
                <p className="policy-text">
                    Your data is stored securely in our cloud infrastructure. We retain your data for the duration of your subscription, and for a period of 60 days after cancellation, allowing you time to export your data.
                </p>
            </div>

            <div className="policy-section">
                <FaLock className="policy-icon" />
                <h2 className="policy-heading">Security Policy</h2>
                <p className="policy-text">
                    We employ industry-standard security measures, including encryption and regular security audits, to protect your data and ensure the integrity of our services.
                </p>
            </div>

            <div className="policy-section">
                <FaHeadset className="policy-icon" />
                <h2 className="policy-heading">Customer Support Policy</h2>
                <p className="policy-text">
                    Our customer support team is available 24/7 to assist you with any inquiries or technical issues. We offer multiple channels of support, including email, phone, and live chat.
                </p>
            </div>

            <div className="policy-section">
                <FaUndoAlt className="policy-icon" />
                <h2 className="policy-heading">Service Level Agreement (SLA)</h2>
                <p className="policy-text">
                    We guarantee 99.9% uptime for our services. In the unlikely event of downtime, we will provide compensation according to the terms outlined in our SLA.
                </p>
            </div>
        </div>
        </>
    );
}
