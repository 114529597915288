// import React from "react";
// import styled from "styled-components";
// // Components
// import FullButton from "../Buttons/FullButton";
// // Assets
// import RollerIcon from "../../assets/svg/Services/RollerIcon";
// import MonitorIcon from "../../assets/svg/Services/MonitorIcon";
// import BrowserIcon from "../../assets/svg/Services/BrowserIcon";
// import PrinterIcon from "../../assets/svg/Services/PrinterIcon";
// import CheckMark from "../../assets/svg/Checkmark";

// export default function PricingTable({ icon, price, title, text,  offers, action }) {
//   let getIcon;

//   switch (icon) {
//     case "roller":
//       getIcon = <RollerIcon />;
//       break;
//     case "monitor":
//       getIcon = <MonitorIcon />;
//       break;
//     case "browser":
//       getIcon = <BrowserIcon />;
//       break;
//     case "printer":
//       getIcon = <PrinterIcon />;
//       break;
//     default:
//       getIcon = <RollerIcon />;
//       break;
//   }

//   return (
//     <Wrapper className="whiteBg radius8 shadow">
//       <div className="flexSpaceCenter">
//         {getIcon}
//         <p className="font30 extraBold">{price}</p>
//       </div>
//       <div style={{ margin: "30px 0" }}>
//         <h4 className="font30 extraBold">{title}</h4>
//         <p className="font13">{text}</p>
//       </div>
//       <div>
//         {offers
//           ? offers.map((item, index) => (
//               <div className="flexNullCenter" style={{ margin: "15px 0" }} key={index}>
//                 <div style={{ position: "relative", top: "-1px", marginRight: "15px" }}>
//                   {item.cheked ? (
//                     <div style={{ minWidth: "20px" }}>
//                       <CheckMark />
//                     </div>
//                   ) : (
//                     <div style={{ minWidth: "20px" }}></div>
//                   )}
//                 </div>
//                 <p className="font20 extraBold">{item.name}</p>
//               </div>
//             ))
//           : null}
//       </div>
//       <div style={{ maxWidth: "120px", margin: "30px auto 0 auto" }}>
//         <FullButton title="Buy" action={action} />
//       </div>
//     </Wrapper>
//   );
// }

// const Wrapper = styled.div`
//   width: 100%;
//   text-align: left;
//   padding: 20px 30px;
//   margin-top: 30px;
// `;


// PricingTable.js
import React, { useContext, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faEdit, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import AuthContext from '../AuthProvider';
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import axios from 'axios';
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Table,
} from "reactstrap";
import ipaddress from '../ipaddress';

export default function PricingTable({ icon, price, title, text, offers, action, isPurchased, branch, planId,user }) {

  const navigate = useNavigate();
  const {
    jwtToken,
    userId,
    username,
    branchId,
    companyid,
    role,
    companyname,
    branchname,
    autoid,
    isAuthenticated,
    login,
    logout,
  } = useContext(AuthContext) || {};

  const handleAction = () => {
    if (!jwtToken) {
      toast.warn("Please register or login first to proceed.", {
        position: 'top-center',
      });
      navigate('/login');
    } else {
      action();
    }
  };

  const [formState, setFormState] = useState({
    companyId: '',
    branchId: '',
    userId: '',
    autoId: '',
    planId: '',
    username: '',
    password: '',
    encodedPassword: '',
    email: '',
    mobile: '',
    createdBy: '',
    createdDate: null, // This should be a Date object
    editedBy: '',
    editedDate: null, // This should be a Date object
  });

  const [flag, setFlag] = useState('add');

  const [isModalOpenForAddUser, setIsModalOpenForAddUser] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpenForAddUser(true)
    getUserData();
  }

  const closeModal = () => {
    setIsModalOpenForAddUser(false);
    setFormState({
      companyId: '',
      branchId: '',
      userId: '',
      autoId: '',
      username: '',
      password: '',
      encodedPassword: '',
      planId: '',
      email: '',
      mobile: '',
      createdBy: '',
      createdDate: null, // This should be a Date object
      editedBy: '',
      editedDate: null, // This should be a Date object
    })
    setFlag('add');
    setFormErrors({
      userId: '',
      username: '',
      password: '',
      email: '',
      mobile: ''
    })
    document.getElementById('userId').classList.remove('error-border');
    document.getElementById('username').classList.remove('error-border');
    document.getElementById('password').classList.remove('error-border');
    document.getElementById('email').classList.remove('error-border');
    document.getElementById('mobile').classList.remove('error-border');
  }

  function handleInputChange(e) {
    const inputValue = e;
    const numericInput = inputValue.replace(/[^0-9.]/g, '');
    const parts = numericInput.split('.');
    const integerPart = parts[0];
    let decimalPart = parts[1];

    // Limit decimal places if needed
    if (decimalPart !== undefined) {
      decimalPart = `.${decimalPart.slice(0, 2)}`;
    }

    const sanitizedInput = decimalPart !== undefined ? `${integerPart}${decimalPart}` : integerPart;
    return sanitizedInput;
  };

  const validateMobile = (mobileNo) => /^[6-9][0-9]{9}$/.test(mobileNo);
  const validateEmail = (email) => /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'mobile') {

      setFormState((prevState) => ({
        ...prevState,
        [name]: handleInputChange(value),
      }));
    }
    else {
      const { name, value } = e.target;
      setFormState((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }

  };

  const [formErrors, setFormErrors] = useState({
    userId: '',
    username: '',
    password: '',
    email: '',
    mobile: ''
  })


  const handleClear = () => {
    setFormErrors({
      userId: '',
      username: '',
      password: '',
      email: '',
      mobile: ''
    })

    setFormState({
      companyId: '',
      branchId: '',
      userId: '',
      autoId: '',
      username: '',
      password: '',
      encodedPassword: '',
      email: '',
      planId: '',
      mobile: '',
      createdBy: '',
      createdDate: null, // This should be a Date object
      editedBy: '',
      editedDate: null, // This should be a Date object
    })
    setFlag('add');
    document.getElementById('userId').classList.remove('error-border');
    document.getElementById('username').classList.remove('error-border');
    document.getElementById('password').classList.remove('error-border');
    document.getElementById('email').classList.remove('error-border');
    document.getElementById('mobile').classList.remove('error-border');
  }


  const handleSave = () => {
    setFormErrors({
      userId: '',
      username: '',
      password: '',
      email: '',
      mobile: ''
    })
    document.getElementById('userId').classList.remove('error-border');
    document.getElementById('username').classList.remove('error-border');
    document.getElementById('password').classList.remove('error-border');
    document.getElementById('email').classList.remove('error-border');
    document.getElementById('mobile').classList.remove('error-border');
    const submitBtn = document.getElementById("submitbtn");
    submitBtn.disabled = true;
    let errors = {};

    if (!formState.userId) {
      errors.userId = "User id is required."
      document.getElementById('userId').classList.add('error-border');
    }
    if (!formState.username) {
      errors.username = "Username is required."
      document.getElementById('username').classList.add('error-border');
    }
    if (!formState.password) {
      errors.password = "Password is required."
      document.getElementById('password').classList.add('error-border');
    }
    if (!formState.email) {
      errors.email = "Email id is required."
      document.getElementById('email').classList.add('error-border');
    }
    else {
      if (!validateEmail(formState.email)) {
        errors.email = "Invalid email id."
        document.getElementById('email').classList.add('error-border');
      }
    }
    if (!formState.mobile) {
      errors.mobile = "Mobile no is required."
      document.getElementById('mobile').classList.add('error-border');
    }
    else {
      if (!validateMobile(formState.mobile)) {
        errors.mobile = "Invalid mobile no."
        document.getElementById('mobile').classList.add('error-border');
      }
    }
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      submitBtn.disabled = false;
      return;
    }

    axios.post(`${ipaddress}userdata/saveData?cid=${companyid}&bid=${branch}&user=${userId}&flag=${flag}&planId=${planId}`, formState, {
      headers: {
        'Authorization': `Bearer ${jwtToken}`
      }
    })
      .then((response) => {
        setFormState(response.data);
        toast.success("Data save successfully!!", {
          autoClose: 800
        })
        submitBtn.disabled = false;
        getUserData();
        handleClear();
      })
      .catch((error) => {
        toast.error(error.response.data, {
          autoClose: 800
        })
        submitBtn.disabled = false;
      })

  }

  const [userData, setUserData] = useState([]);

  const getUserData = () => {
    axios.get(`${ipaddress}userdata/getData?cid=${companyid}&bid=${branch}&plan=${planId}`, {
      headers: {
        'Authorization': `Bearer ${jwtToken}`
      }
    })
      .then((response) => {
        setUserData(response.data);
      })
      .catch((error) => {

      })
  }

  const handleEdit = (id) =>{
    axios.get(`${ipaddress}userdata/getDataById?cid=${companyid}&bid=${branch}&auto=${id}`,{
      headers: {
        'Authorization': `Bearer ${jwtToken}`
      }
    })
    .then((response)=>{
      setFormState(response.data);
      setFlag('edit');
    })
    .catch((error)=>{

    })
  } 

  const handleDelete = (id) =>{
    axios.post(`${ipaddress}userdata/deleteData?cid=${companyid}&bid=${branch}&auto=${id}`,null,{
      headers: {
        'Authorization': `Bearer ${jwtToken}`
      }
    })
    .then((response)=>{
      if(response.data === 'success'){
        toast.error("Data deleted successfully!!",{
          autoClose:800
        })
        getUserData();
      }
      else{
        toast.error("Something went wrong!!",{
          autoClose:800
        })
      }
    })
    .catch((error)=>{
      toast.error("Something went wrong!!",{
        autoClose:800
      })
    })
  } 

  return (
    <>
      <TableWrapper>
        <TableHeader>
          <FontAwesomeIcon icon={icon} size="2x" />
          <h2>{title}</h2>
          <h3>{price}</h3>
        </TableHeader>
        <TableBody>
          <p>{text}</p>
          <OfferList>
            {offers.map((offer, index) => (
              <OfferItem key={index}>
                {offer.checked && <FontAwesomeIcon icon={faCheck} style={{ marginRight: '10px', color: 'green' }} />}
                {offer.name}
              </OfferItem>
            ))}
          </OfferList>
          <ActionButton onClick={handleAction} style={{ backgroundColor: isPurchased ? 'green' : '', marginRight: 10 }} disabled={isPurchased}>
            {isPurchased &&  <FontAwesomeIcon style={{marginRight:5}}  icon={faCheck}/>}
            {isPurchased ? 'Purchased' : 'Buy'}
          </ActionButton>
          {isPurchased && (
            <ActionButton onClick={handleOpenModal}  >
              Add User
            </ActionButton>
          )}

        </TableBody>
      </TableWrapper>

      <Modal Modal isOpen={isModalOpenForAddUser} onClose={closeModal} toggle={closeModal} style={{ maxWidth: '800px', wioverflow: '-moz-hidden-unscrollable' }}>

        <ModalHeader toggle={closeModal} style={{
          backgroundColor: 'red', color: 'black', fontFamily: 'Your-Heading-Font', textAlign: 'center', background: 'red',
          boxShadow: '0px 5px 10px rgba(0, 77, 64, 0.3)',
          border: '1px solid rgba(0, 0, 0, 0.3)',
          borderRadius: '0',
          backgroundColor: 'red', backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat', backgroundPosition: 'center',
        }}   >
          <h5 className="pageHead" style={{ fontFamily: 'Your-Heading-Font', color: 'white', }} > <FontAwesomeIcon
            icon={faPlus}
            style={{
              marginRight: '8px',
              color: 'white', // Set the color to golden
            }}
          />Add User</h5>

        </ModalHeader>
        <ModalBody style={{ backgroundImage: 'url(https://img.freepik.com/free-vector/gradient-wavy-background_23-2149123392.jpg?t=st=1694859409~exp=1694860009~hmac=b397945a9c2d45405ac64956165f76bd10a0eff99334c52cd4c88d4162aad58e)', backgroundSize: 'cover', fontSize: 12 }}>
          <Row>
            <Col md={4}>
              <FormGroup>
                <Label for="userId">User ID</Label> <span style={{ color: 'red' }}>*</span>
                <Input
                  type="text"
                  name="userId"
                  id="userId"
                  value={formState.userId}
                  onChange={handleChange}
                  maxLength={50}
                  style={{ height: '33px' }}
                />
                <div style={{ color: 'red' }} className="error-message">{formErrors.userId}</div>
              </FormGroup>
            </Col>

            <Col md={4}>
              <FormGroup>
                <Label for="username">Username</Label> <span style={{ color: 'red' }}>*</span>
                <Input
                  type="text"
                  name="username"
                  id="username"
                  value={formState.username}
                  onChange={handleChange}
                  maxLength={100}
                  style={{ height: '33px' }}
                />
                <div style={{ color: 'red' }} className="error-message">{formErrors.username}</div>
              </FormGroup>
            </Col>

            <Col md={4}>
              <FormGroup>
                <Label for="password">Password</Label> <span style={{ color: 'red' }}>*</span>
                <Input
                  type="password"
                  name="password"
                  id="password"
                  value={formState.password}
                  onChange={handleChange}
                  maxLength={20}
                  style={{ height: '33px' }}
                />
                <div style={{ color: 'red' }} className="error-message">{formErrors.password}</div>
              </FormGroup>
            </Col>


          </Row>
          <Row>
            <Col md={4}>
              <FormGroup>
                <Label for="email">Email</Label> <span style={{ color: 'red' }}>*</span>
                <Input
                  type="email"
                  name="email"
                  id="email"
                  value={formState.email}
                  onChange={handleChange}
                  maxLength={50}
                  style={{ height: '33px' }}
                />
                <div style={{ color: 'red' }} className="error-message">{formErrors.email}</div>
              </FormGroup>
            </Col>

            <Col md={4}>
              <FormGroup>
                <Label for="mobile">Mobile</Label> <span style={{ color: 'red' }}>*</span>
                <Input
                  type="text"
                  name="mobile"
                  id="mobile"
                  value={formState.mobile}
                  onChange={handleChange}
                  maxLength={15}
                  style={{ height: '33px' }}
                />
                <div style={{ color: 'red' }} className="error-message">{formErrors.mobile}</div>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col className="text-center">
              <button
                type="button"
                className="btn  btn-danger"
                id="submitbtn"
                onClick={handleSave}
                disabled={(flag === 'add' && user === userData.length)}
              >

                Save
              </button>
              <button
                type="button"
                className="btn  btn-danger"
                style={{ marginLeft: 10 }}
                onClick={handleClear}
              >

                Clear
              </button>
            </Col>
          </Row>
          <div style={{ marginTop: 10 }} className="table-responsive">
            <Table className="table table-bordered custom-table">
              <thead style={{ backgroundColor: 'rgb(226 232 240)' }}>
                <tr className="text-center" >
                  <th style={{ backgroundColor: 'white' }} scope="col" >#</th>
                  <th style={{ backgroundColor: 'white' }} scope="col">User Id</th>
                  <th style={{ backgroundColor: 'white' }} scope="col">Username</th>
                  <th style={{ backgroundColor: 'white' }} scope="col">Email</th>
                  <th style={{ backgroundColor: 'white' }} scope="col">Mobile</th>
                  <th style={{ backgroundColor: 'white' }} scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {userData.map((item, index) => (
                  <tr key={index} className='text-center'>
                    <td>{index + 1}</td>
                    <td>{item.userId}</td>
                    <td>{item.username}</td>
                    <td>{item.email}</td>
                    <td>{item.mobile}</td>
                    <td>
                      <button
                        type="button"
                        className="btn  btn-danger"
                        onClick={() => handleEdit(item.autoId)}
                        id="submitbtn"
                        style={{ marginRight: "5px" }}
                      >
                        <FontAwesomeIcon
                          icon={faEdit}
                        />

                      </button>
                      <button
                        type="button"
                        className="btn  btn-danger"
                        onClick={() => handleDelete(item.autoId)}
                        id="submitbtn"
                      >
                        <FontAwesomeIcon
                          icon={faTrash}
                        />

                      </button>


                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};



const TableWrapper = styled.div`
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
   width:400px
`;

const TableHeader = styled.div`
  margin-bottom: 20px;

  h2 {
    margin: 10px 0;
  }

  h3 {
    color: #0070f3;
    margin: 10px 0;
  }
`;

const TableBody = styled.div`
  p {
    margin-bottom: 20px;
   
  }
`;

const OfferList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0 0 20px;
`;

const OfferItem = styled.li`
  margin: 10px 0;
  display: flex;
  align-items: center;
`;

const ActionButton = styled.button`
  background-color: #0070f3;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
  transition: background-color 0.3s, transform 0.3s;

  &:hover {
    background-color: #005bb5;
    transform: scale(1.05);
  }
`;

