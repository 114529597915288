import React from "react";
import styled from "styled-components";
 import { Link } from "react-scroll";
import { Link  as MyLink} from "react-router-dom";
import RapportSoftlogo from "../../assets/img/RapportSoftlogo.png";
import { Row, Col } from "reactstrap";
import Privacy from "./Privacy";

const Footer = () => {
  const getCurrentYear = () => {
    return new Date().getFullYear();
  };

  return (
    <DarkBg>
    <div className="container">
        <InnerWrapper className="flexSpaceCenter" style={{ padding: "30px 0" }}>
          <Row>
          <Col 
      md={3} 
      // style={{ 
      //   display: 'flex', 
      //   flexDirection: 'column', 
      //   alignItems: 'flex-start', 
       
      // }}
    >

            <Section>
            <Link className="flexCenter animate pointer" to="about" smooth={true} offset={-80}>
              <Logo
                alt="RapportSoftlogo"
                src={RapportSoftlogo}
              />
              
            </Link>
          </Section>
            </Col>

            <Col md={3}  

       >
            <Section>
            <SectionTitle>Services</SectionTitle>
            <Link className="whiteColor animate pointer font13" to="services" smooth={true} offset={-80}>
            Software Development
            </Link><br/>
            <Link className="whiteColor animate pointer font13" to="services" smooth={true} offset={-80}>
            Testing & QA
            </Link><br/>
            <Link className="whiteColor animate pointer font13" to="services" smooth={true} offset={-80}>
            Application Services
            </Link><br/>
           <MyLink className="whiteColor animate pointer font13" to="/terms" smooth={true} offset={-80} >
           Term & Condition
            </MyLink><br/>
            <MyLink to="/privacy" className="whiteColor animate pointer font13" smooth={true} offset={-80}>
      Privacy Policy
    </MyLink><br/>
    <MyLink to="/cancelandrefund" className="whiteColor animate pointer font13" smooth={true} offset={-80}>
     Cancelation And Refund
    </MyLink><br/>
     
          </Section>
              
</Col>


<Col 
      md={3} 
      // style={{ 
      //   display: 'flex', 
      //   flexDirection: 'column', 
      //   alignItems: 'flex-start', 
        
      // }}
    >
<Section>
            <SectionTitle>Products</SectionTitle>
            <Link  className="whiteColor animate pointer font13"  activeClass="active"  to="projects" spy={true} smooth={true} offset={-80}>
            Empty Yard Management System 
            </Link><br/>
            <Link  className="whiteColor animate pointer font13" activeClass="active" to="projects" spy={true} smooth={true} offset={-80}>
           BondedWare House
            </Link>
           
          </Section>
              
</Col>

<Col 
      md={3} 
      // style={{ 
      //   display: 'flex', 
      //   flexDirection: 'column', 
      //   alignItems: 'flex-start', 
       
      // }}
    >

<Section>
            <SectionTitle>Contact</SectionTitle>
            <Link  className="whiteColor animate pointer font13">
              Address: Rapportsoft Consulting & Technology Pvt Ltd,
              Office No-321,XION, Hinjawadi,Pune,
              Maharashtra-411057
            </Link>
<br/>
            <Link className="whiteColor animate pointer font13">
            Email: corp@rapportsoft.co.in
Phone: 8329469330 | 9011075932
            </Link>
          </Section>
</Col>
          </Row>
       
       
       
         
        </InnerWrapper>
  <div style={{paddingTop:0 ,paddingBottom:9}}>
        <FooterText className="whiteColor font13" style={{ textAlign: 'center' }}>
          {/* © {getCurrentYear()} - <Link to='/https://rapportsoft.co.in/about-us' className="purpleColor font13">Rapportsoft</Link> All Right Reserved */}
          <span style={{ fontSize: '16px' }}>© {getCurrentYear()} - </span> {/* Increased font size */}
          <StyledLink href="https://rapportsoft.co.in/" className="purpleColor " style={{fontSize:"16px"}}>Rapportsoft Consulting and Technology Pvt. Ltd</StyledLink> 
          <span style={{ fontSize: '16px',paddingLeft:'4px' }}>All Rights Reserved</span> {/* Increased font size */}
          <Link className="whiteColor animate pointer font13" to="home" smooth={true} offset={-80} style={{float:'right'}}>
               Back to top
             </Link> 
        </FooterText>


        </div>
      </div>
    </DarkBg>
  );
};

const DarkBg = styled.div`
  background-color: #000;
`;

const Container = styled.div`
  width: 100%;
`;

const InnerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 30px 0;
  @media (max-width: 768px) {
    flex-wrap: wrap;
  }

  
  @media (max-width: 768px) {
   
    flex-wrap: wrap;
  }
  @media (max-width: 1200px) {
   
    flex-wrap: wrap;
  }
  @media (max-width: 992px) {
    
    flex-wrap: wrap;
  }

  @media (max-width: 576px) {
   
    flex-wrap: wrap;
  }

  @media (max-width: 480px) {
   
    flex-wrap: wrap;
  }
`;

const Section = styled.div`
  // display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 9px;
  margin-left: 9px;
  @media (max-width: 768px) {
    margin-right: 0;
    margin-bottom: 20px;
  }
  @media (max-width: 1200px) {
    margin-right: 0;
    margin-bottom: 20px;
  }
  @media (max-width: 992px) {
    margin-right: 0;
    margin-bottom: 20px;
  }

  @media (max-width: 576px) {
    margin-right: 0;
    margin-bottom: 20px;
  }

  @media (max-width: 480px) {
    margin-right: 0;
    margin-bottom: 20px;
  }
`;

const SectionTitle = styled.h3`
  font-size: 18px;
  color: white;
  bottom:4px;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 1px;
    width: 100%;
    height: 2px;
    background-color: red; /* Red underline */
  }
`;

const Logo = styled.img`
  width: 180px;
  height: auto;
  margin-bottom: 10px;
`;

const FooterText = styled.p`
  color: white;
  font-size: 13px;
`;

const StyledLink = styled.a`
  color: inherit;
  text-decoration: none;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -2px;
    width: 100%;
    height: 1px;
    background-color: #6a0dad; /* Purple color */
    transition: all 0.3s ease;
    transform: scaleX(0);
    transform-origin: right;
  }
  &:hover:after {
    transform: scaleX(1);
    transform-origin: left;
  }
`;
export default Footer;