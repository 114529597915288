import AuthContext from "./AuthProvider";
import { useNavigate, useLocation } from "react-router-dom";
import React, { useEffect, useState, useContext, useCallback, useRef, useId } from "react";
import DropdownButton from 'react-bootstrap/DropdownButton';
import Button from 'react-bootstrap/Button';
import "./Style1.css";
import ipaddress from "./ipaddress"
import Dropdown from 'react-bootstrap/Dropdown';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import DatePicker from "react-datepicker";
import Swal from 'sweetalert2';
import ReactLoading from 'react-loading';
import {
    Card,
    CardBody,
    Container,
    Row,
    Col,
    Form,
    FormGroup,
    Label,
    Input,
    Table,
} from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, faArrowCircleLeft, faArrowCircleRight, faArrowLeft, faArrowTurnRight, faAtom, faBolt, faCity, faCodeBranch, faCross, faExternalLink, faExternalLinkAlt, faGavel, faGear, faHand, faHandFist, faHandHoldingHand, faHandsHolding, faHistory, faIdBadge, faIdCardAlt, faIdCardClip, faPenClip, faPenFancy, faPencil, faPerson, faPersonBooth, faPlaneDeparture, faPlus, faSearch, faSquarePen, faTentArrowTurnLeft, faTruckArrowRight, faUpload, faUserCircle, faWeightHanging, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { faCheck, faSave, faAd, faBan, faSyncAlt, faCancel, faCog, faPrint, faXmark, faFileLines, faChessKing } from '@fortawesome/free-solid-svg-icons';
import axios from "axios";
import { toast } from "react-toastify";
import { Pagination } from "react-bootstrap";
import Select from 'react-select';

export default function Branch() {
    const {
        jwtToken,
        username,
        login,
        logout,
    } = useContext(AuthContext);

    const [isOpenAddBranchModal,setIsOpenAddBranchModal] = useState(false);

    const handleOpenBranch = () =>{
        setIsOpenAddBranchModal(true);
    }

    const closeBranch = () =>{
        setIsOpenAddBranchModal(false);
    }

    return (
        <div style={{ marginTop: 50 }}>
            <div className="container">
                <div >
                    <h5 className="pageHead" style={{ fontFamily: 'Your-Heading-Font', }} > <FontAwesomeIcon
                        icon={faCodeBranch}
                        style={{
                            marginRight: '8px',
                            color: 'black', // Set the color to golden
                        }}
                    />Add New Branches</h5>

                </div>

                <Card>
                    <CardBody>
                        <Row className="text-end">
                            <Col >
                                <button
                                    type="button"
                                    className="btn me-md-2  btn-outline-success"
                                    style={{ marginTop: '2.0vw' }}
                                    onClick={handleOpenBranch}
                                >
                                    <FontAwesomeIcon icon={faAdd} style={{ marginRight: '5px' }} />
                                    Add Branch
                                </button>
                            </Col>
                        </Row>
                        <hr />
                        <div className="table-responsive">
                            <Table className="table table-bordered custom-table">
                                <thead style={{ backgroundColor: 'rgb(226 232 240)' }}>
                                    <tr className="text-center">
                                        <th style={{ backgroundColor: 'white' }} scope="col" >#</th>
                                        <th style={{ backgroundColor: 'white' }} scope="col">Branch Name</th>
                                        <th style={{ backgroundColor: 'white' }} scope="col">Address</th>
                                        <th style={{ backgroundColor: 'white' }} scope="col">GST No</th>
                                        <th style={{ backgroundColor: 'white' }} scope="col">Email Id</th>
                                        <th style={{ backgroundColor: 'white' }} scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="text-center">
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    </CardBody>
                </Card>

            </div>

            <Modal Modal isOpen={isOpenAddBranchModal} onClose={closeBranch} toggle={closeBranch} style={{ maxWidth: '800px', wioverflow: '-moz-hidden-unscrollable' }}>

                <ModalHeader toggle={closeBranch} style={{
                    backgroundColor: '#80cbc4', color: 'black', fontFamily: 'Your-Heading-Font', textAlign: 'center', background: '#26a69a',
                    boxShadow: '0px 5px 10px rgba(0, 77, 64, 0.3)',
                    border: '1px solid rgba(0, 0, 0, 0.3)',
                    borderRadius: '0',
                     backgroundColor: 'red',
                    // backgroundColor: 'rgba(0, 0, 0, 0.3)',
                    // backgroundImage: 'url("https://images.pexels.com/photos/1103970/pexels-photo-1103970.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1")',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    //backgroundPosition: 'center',
                    backgroundPosition: 'center',
                }}   >
                    <h5 className="pageHead" style={{ fontFamily: 'Your-Heading-Font', color: 'white',  }} > <FontAwesomeIcon
                        icon={faHistory}
                        style={{
                            marginRight: '8px',
                            color: 'white', // Set the color to golden
                        }}
                    />Export Transaction History</h5>

                </ModalHeader>
                <ModalBody style={{ backgroundImage: 'url(https://img.freepik.com/free-vector/gradient-wavy-background_23-2149123392.jpg?t=st=1694859409~exp=1694860009~hmac=b397945a9c2d45405ac64956165f76bd10a0eff99334c52cd4c88d4162aad58e)', backgroundSize: 'cover' }}>
                </ModalBody>
            </Modal>
        </div>
    )
}
