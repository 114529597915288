import React from 'react';
import ReactDOM from 'react-dom';
// import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./style/flexboxgrid.min.css";
import './style/index.css';
import App from './App';
import { NextUIProvider } from '@nextui-org/react';
import reportWebVitals from './reportWebVitals';

// ReactDOM.render(
//   <>
//    <NextUIProvider>
//     <App />
//     </NextUIProvider>
//   </>,
//   document.getElementById('root')
// );
// reportWebVitals();
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <NextUIProvider>
    <App />
    </NextUIProvider>
  </React.StrictMode>
  
);


reportWebVitals();

