import React, { useState } from "react";
import styled from "styled-components";
import HeaderImage from "../../assets/img/img-1.png";
import Quality from "../../assets/img/quality.avif";
import Coustomer from "../../assets/img/coustomer.avif";
import Vission from "../../assets/img/visionmm.png";
import Mission from "../../assets/img/mission .png";
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Button,
  Label,
  Input,
  Table,
} from "reactstrap";
import BlogBox from "../Elements/BlogBox";
import FullButton from "../Buttons/FullButton";
import TestimonialSlider from "../Elements/TestimonialSlider";
import img1 from "../../Images/Picture1.png"
import img2 from "../../Images/Picture2.png"
import img3 from "../../Images/Picture3.png"
import img4 from "../../Images/Picture4.png"
import img5 from "../../Images/Picture5.png"
import img6 from "../../Images/Picture6.png"
import img7 from "../../Images/Picture7.png"
import img8 from "../../Images/Picture8.png"
import img9 from "../../Images/Picture9.png"
import img10 from "../../Images/Picture10.png"
import img11 from "../../Images/Picture11.png"
import img12 from "../../Images/Picture12.png"
import 'bootstrap/dist/css/bootstrap.min.css';
import Slider from "react-slick";

export default function Blog() {
  const [activeTab, setActiveTab] = useState('vision');

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 360,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <Wrapper id="about">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo style={{ paddingTop: 18 }}>
            <h1 className="font40 extraBold">About Rapportsoft Consulting & Technology </h1>
            <div className="container" style={{ paddingTop: 9 }}>
              <h2 className="font40 extraBold"></h2>
              <div className="row">
                <div className="col-md-6">
                  <img src={HeaderImage} alt="Company" className="img-fluid company-image" />
                </div>

                <div className="col-md-6">
                  <p className="font15" style={{marginLeft:"9px"}}>
                    Rapportsoft Consulting & Technology Pvt. Ltd. is a premier provider of innovative and integrated enterprise solutions. Established in 2005, we have a proven track record of delivering high-quality software development projects. Our services are tailored to meet the unique needs of our clients, ensuring that their IT strategies align with their business goals. We are an authorized VGM vendor approved by D. G. Shipping Mumbai.
                  </p>
                  {/* <img src={HeaderImage} alt="Company" className="company-image" /> */}
                </div>
              </div>
            </div>
          </HeaderInfo>
          <CompanyInfoSection>



            <TabContainer>
              <TabButton active={activeTab === 'vision'} onClick={() => setActiveTab('vision')}>Vision</TabButton>
              <TabButton active={activeTab === 'mission'} onClick={() => setActiveTab('mission')}>Mission</TabButton>
              <TabButton active={activeTab === 'quality'} onClick={() => setActiveTab('quality')}>Quality</TabButton>
              <TabButton active={activeTab === 'customer'} onClick={() => setActiveTab('customer')}>Customer</TabButton>
            </TabContainer>
            {activeTab === 'vision' && (
              <Section>
                <h2 className="font30 extraBold">Vision</h2>
                <p className="font15 " style={{marginLeft:'7px'}} >To be the best global Information Technology partner to customers providing innovative and integrated enterprise solutions in CFS (Container Freight Station), ICD, Logistics, Transportation, Empty Container Yard, Liner, Cold Storage Management, Freight Forwarding (Air Freight and Sea Freight), HR & Payroll, Digital Signature API, E-Invoice, SCMRT, Inventory Management System, Mobile application, WhatsApp, SMS Notification and Third-Party API communication ensuring customer satisfaction.</p>
                {/* <img src={Vission} alt="Vision" /> */}
              </Section>
            )}
            {activeTab === 'mission' && (
              <Section>
                <h2 className="font30 extraBold">Mission</h2>
                <p className="font15" style={{marginLeft:'7px'}}>To establish strategic partnerships with our clients, in the areas of Product development and provide competitive IT solutions while fulfilling our employee’s aspirations. To create a business impact and become a competitive advantage for our clients, by virtue of our world-class and cost-effective service delivery.</p>
                {/* <img src={Mission} alt="Mission" /> */}
              </Section>
            )}
            {activeTab === 'quality' && (
              <Section>
                <h2 className="font30 extraBold">Quality</h2>
                <p className="font15" style={{marginLeft:'7px'}}>We pay special attention to the quality assurance of the products and services we deliver. We believe that this is the best way to make products work as effectively as possible. Our clients can rely on our products working stably and as designed because we put an emphasis on quality assurance as a key factor in project success. By investing into quality assurance, we invest into our customers' business stability, and thus - value.</p>
                {/* <img src={Quality} alt="Quality" /> */}
              </Section>
            )}
            {activeTab === 'customer' && (
              <Section>
                <h2 className="font30 extraBold">Customer</h2>
                <p className="font15" style={{marginLeft:'7px'}}>Rapportsoft is dedicated to its customers and partners. We believe that the most important factor of our success is the success of our clients, (and provide them with tools for successful business). Rapportsoft both in idea and realization, is a client-oriented company. By supplying our customers with top resources and skills, we fulfil our main goal - add value to their businesses through knowledgeable application of information technologies. Because we work closely with and for our customers, we are successful only when our clients succeed.</p>
                {/* <img src={Coustomer} alt="Customer" /> */}
              </Section>
            )}

          </CompanyInfoSection>

        </div>
      </div>
      {/* <div className="lightClinet" style={{ padding: '50px 0' }}>
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Rapportsoft Software Product</h1>

          </HeaderInfo>

          <div className="horizontal-scroll">
            
       
            <table>
              <tr>
                <td className="text-center" style={{ paddingRight: 100 }}>
                  <FormGroup>
                    <img src={img1} alt="" className="img-fluid" />
                    <div style={{ marginTop: 5, fontWeight: 800 }}>CFS Management System</div>
                    <div style={{ marginTop: 10, fontSize: 14 }}>Comprehensive software solution for container freight station operations</div>
                  </FormGroup>
                </td>
                <td className="text-center" style={{ paddingRight: 100 }}>
                  <FormGroup>
                    <img src={img2} alt="" className="img-fluid" />
                    <div style={{ marginTop: 5, fontWeight: 800 }}>GemTrade Air Cargo</div>
                    <div style={{ marginTop: 10, fontSize: 14 }}>Integrated platform for the gemstone and jewellery trade industry</div>
                  </FormGroup>
                </td>
                <td className="text-center" style={{ paddingRight: 100 }}>
                  <FormGroup>
                    <img src={img3} alt="" className="img-fluid" />
                    <div style={{ marginTop: 5, fontWeight: 800 }}>Bonded & General Warehouse</div>
                    <div style={{ marginTop: 10, fontSize: 14 }}>Streamlined software for managing bond and general warehouse operations</div>
                  </FormGroup>
                </td>
                <td className="text-center" style={{ paddingRight: 100 }}>
                  <FormGroup>
                    <img src={img4} alt="" className="img-fluid" />
                    <div style={{ marginTop: 5, fontWeight: 800 }}>EYMS</div>
                    <div style={{ marginTop: 10, fontSize: 14 }}>Optimize container flows with our advanced Empty Yard CFS Management System</div>
                  </FormGroup>
                </td>
                <td className="text-center" style={{ paddingRight: 100 }}>
                  <FormGroup>
                    <img src={img5} alt="" className="img-fluid" />
                    <div style={{ marginTop: 5, fontWeight: 800 }}>Haulage to ICD Communication System: Seamless Rail Logistics</div>
                    <div style={{ marginTop: 10, fontSize: 14 }}>Rail management system contain CUL/VIZ to ICD Birguni</div>
                  </FormGroup>
                </td>
                <td className="text-center" style={{ paddingRight: 100 }}>
                  <FormGroup>
                    <img src={img6} alt="" className="img-fluid" />
                    <div style={{ marginTop: 5, fontWeight: 800 }}>Yard Management System (Non-Customs) Domestic</div>
                    <div style={{ marginTop: 10, fontSize: 14 }}>Domestic Import and Export with billing</div>
                  </FormGroup>
                </td>
                <td className="text-center" style={{ paddingRight: 100 }}>
                  <FormGroup>
                    <img src={img7} alt="" className="img-fluid" />
                    <div style={{ marginTop: 5, fontWeight: 800 }}>Ocean and Air Freight Management System</div>
                    <div style={{ marginTop: 10, fontSize: 14 }}>OFR System contains ocean and air freight operations and documents</div>
                  </FormGroup>
                </td>
                <td className="text-center" style={{ paddingRight: 100 }}>
                  <FormGroup>
                    <img src={img8} alt="" className="img-fluid" />
                    <div style={{ marginTop: 5, fontWeight: 800 }}>Shipping Line IGM AND EGM</div>
                    <div style={{ marginTop: 10, fontSize: 14 }}>Contains operations related to IGM & EGM</div>
                  </FormGroup>
                </td>
                <td className="text-center" style={{ paddingRight: 100 }}>
                  <FormGroup>
                    <img src={img9} alt="" className="img-fluid" />
                    <div style={{ marginTop: 5, fontWeight: 800 }}>Shipping Line EGM Merge System</div>
                    <div style={{ marginTop: 10, fontSize: 14 }}>This system generates EGM, MCD format report</div>
                  </FormGroup>
                </td>
                <td className="text-center" style={{ paddingRight: 100 }}>
                  <FormGroup>
                    <img src={img10} alt="" className="img-fluid" />
                    <div style={{ marginTop: 5, fontWeight: 800 }}>Bulk Design Application</div>
                    <div style={{ marginTop: 10, fontSize: 14 }}>System generates bulk digital sign on any PDF</div>
                  </FormGroup>
                </td>
                <td className="text-center" style={{ paddingRight: 100 }}>
                  <FormGroup>
                    <img src={img11} alt="" className="img-fluid" />
                    <div style={{ marginTop: 5, fontWeight: 800 }}>E-Invoice Integration</div>
                    <div style={{ marginTop: 10, fontSize: 14 }}>e-invoice portal sends invoice-related data to INC portal and fetching IRM as per GST System</div>
                  </FormGroup>
                </td>
                <td className="text-center" style={{ paddingRight: 100 }}>
                  <FormGroup>
                    <img src={img12} alt="" className="img-fluid" />
                    <div style={{ marginTop: 5, fontWeight: 800 }}>Transportation Software</div>
                    <div style={{ marginTop: 10, fontSize: 14 }}>Transportation software contains all operations related to transportation</div>
                  </FormGroup>
                </td>
              </tr>
            </table>

          </div>






      
        </div>
      </div> */}

<div className="lightClinet" style={{ padding: '50px 0' }}>
      <div className="container">
        <HeaderInfo>
          <h1 className="font40 extraBold">Rapportsoft Software Product</h1>
        </HeaderInfo>
        <div className="horizontal-scroll">
          <Slider {...settings}>
            <div className="slider-item text-center">
              <FormGroup>
                <img src={img1} alt="CFS Management System" className="img-fluid" />
                <div style={{ marginTop: 5, fontWeight: 800 }}>CFS Management System</div>
                <div style={{ marginTop: 10, fontSize: 14 }}>Comprehensive software solution for container freight station operations</div>
              </FormGroup>
            </div>
            <div className="slider-item text-center">
              <FormGroup>
                <img src={img2} alt="GemTrade Air Cargo" className="img-fluid" />
                <div style={{ marginTop: 5, fontWeight: 800 }}>GemTrade Air Cargo</div>
                <div style={{ marginTop: 10, fontSize: 14 }}>Integrated platform for the gemstone and jewellery trade industry</div>
              </FormGroup>
            </div>
            <div className="slider-item text-center">
              <FormGroup>
                <img src={img3} alt="Bonded & General Warehouse" className="img-fluid" />
                <div style={{ marginTop: 5, fontWeight: 800 }}>Bonded & General Warehouse</div>
                <div style={{ marginTop: 10, fontSize: 14 }}>Streamlined software for managing bond and general warehouse operations</div>
              </FormGroup>
            </div>
            <div className="slider-item text-center">
              <FormGroup>
                <img src={img4} alt="EYMS" className="img-fluid" />
                <div style={{ marginTop: 5, fontWeight: 800 }}>EYMS</div>
                <div style={{ marginTop: 10, fontSize: 14 }}>Optimize container flows with our advanced Empty Yard CFS Management System</div>
              </FormGroup>
            </div>
            <div className="slider-item text-center">
              <FormGroup>
                <img src={img5} alt="Haulage to ICD Communication System" className="img-fluid" />
                <div style={{ marginTop: 5, fontWeight: 800 }}>Haulage to ICD Communication System: Seamless Rail Logistics</div>
                <div style={{ marginTop: 10, fontSize: 14 }}>Rail management system contain CUL/VIZ to ICD Birguni</div>
              </FormGroup>
            </div>
            <div className="slider-item text-center">
              <FormGroup>
                <img src={img6} alt="Yard Management System (Non-Customs) Domestic" className="img-fluid" />
                <div style={{ marginTop: 5, fontWeight: 800 }}>Yard Management System (Non-Customs) Domestic</div>
                <div style={{ marginTop: 10, fontSize: 14 }}>Domestic Import and Export with billing</div>
              </FormGroup>
            </div>
            <div className="slider-item text-center">
              <FormGroup>
                <img src={img7} alt="Ocean and Air Freight Management System" className="img-fluid" />
                <div style={{ marginTop: 5, fontWeight: 800 }}>Ocean and Air Freight Management System</div>
                <div style={{ marginTop: 10, fontSize: 14 }}>OFR System contains ocean and air freight operations and documents</div>
              </FormGroup>
            </div>
            <div className="slider-item text-center">
              <FormGroup>
                <img src={img8} alt="Shipping Line IGM AND EGM" className="img-fluid" />
                <div style={{ marginTop: 5, fontWeight: 800 }}>Shipping Line IGM AND EGM</div>
                <div style={{ marginTop: 10, fontSize: 14 }}>Contains operations related to IGM & EGM</div>
              </FormGroup>
            </div>
            <div className="slider-item text-center">
              <FormGroup>
                <img src={img9} alt="Shipping Line EGM Merge System" className="img-fluid" />
                <div style={{ marginTop: 5, fontWeight: 800 }}>Shipping Line EGM Merge System</div>
                <div style={{ marginTop: 10, fontSize: 14 }}>This system generates EGM, MCD format report</div>
              </FormGroup>
            </div>
            <div className="slider-item text-center">
              <FormGroup>
                <img src={img10} alt="Bulk Design Application" className="img-fluid" />
                <div style={{ marginTop: 5, fontWeight: 800 }}>Bulk Design Application</div>
                <div style={{ marginTop: 10, fontSize: 14 }}>System generates bulk digital sign on any PDF</div>
              </FormGroup>
            </div>
            <div className="slider-item text-center">
              <FormGroup>
                <img src={img11} alt="E-Invoice Integration" className="img-fluid" />
                <div style={{ marginTop: 5, fontWeight: 800 }}>E-Invoice Integration</div>
                <div style={{ marginTop: 10, fontSize: 14 }}>e-invoice portal sends invoice-related data to INC portal and fetching IRM as per GST System</div>
              </FormGroup>
            </div>
            <div className="slider-item text-center">
              <FormGroup>
                <img src={img12} alt="Transportation Software" className="img-fluid" />
                <div style={{ marginTop: 5, fontWeight: 800 }}>Transportation Software</div>
                <div style={{ marginTop: 10, fontSize: 14 }}>Transportation software contains all operations related to transportation</div>
              </FormGroup>
            </div>
          </Slider>
        </div>
      </div>
    </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding-top: 20px;
`;

const HeaderInfo = styled.div`
  margin-bottom: 30px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;

const CompanyInfoSection = styled.div`
  padding: 50px 0;
  background-color: #f9f9f9;

  .company-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
    padding-left: 15px;
    padding-right: 15px;

    @media (max-width: 768px) {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
`;

const TabContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  flex-wrap: wrap;
`;

const TabButton = styled.button`
  padding: 10px 20px;
  margin: 10px;
  font-size: 16px;
  font-weight: bold;
  background-color: ${props => (props.active ? '#000' : '#ccc')};
  color: ${props => (props.active ? '#fff' : '#000')};
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #000;
    color: #fff;
  }
`;

const Section = styled.div`
  margin: 30px 0;
  text-align: center;
  padding: 0 20px; /* Added padding for smaller screens */
  
  h2 {
    margin-bottom: 15px;
  }
  p {
    margin-bottom: 20px;
  }
  img {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
    margin: 0 auto;
  }
`;