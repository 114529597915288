import React, { useState } from "react";
import styled from "styled-components";

const ProjectBox = ({ img, title, text, action }) => {
  const [hovered, setHovered] = useState(false);

  return (
    <Wrapper>
      <ImgBtn
      
        className="animate pointer"
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        onClick={action ? () => action() : null}
      >
        <img className="radius8" src={img} alt="project" />
        {/* {hovered && <Overlay />} */}
        {hovered && <Overlay><OverlayText>Learn More</OverlayText></Overlay>}
      </ImgBtn>
      <ProjectInfo >
        <ProjectTitle style={{marginRight:81}}>{title}</ProjectTitle>
        <ProjectText >{text}</ProjectText>
        {/* <p >{text}</p> */}
      </ProjectInfo>
    </Wrapper>
  );
};


const Wrapper = styled.div`
  width: 100%;
  margin-top: 30px;
`;

const ImgBtn = styled.button`
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
  position: relative;
  overflow: hidden;
  cursor: pointer;

  img {
    width: 100%;
    height: auto;
    transition: opacity 0.3s ease;
  }

  :hover img {
    opacity: 0.5;
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  transition: opacity 0.3s ease;
`;

const ProjectInfo = styled.div`
  padding: 10px 0;
`;

const ProjectTitle = styled.h3`
  font-size: 20px;
  font-weight: bold;
  padding-bottom: 10px;
`;

const ProjectText = styled.p`
  font-size: 13px;
`;

const OverlayText = styled.div`
  color: white;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
`;
export default ProjectBox;


