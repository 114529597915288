import React,{ useEffect, useState, useContext } from 'react';
import {
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBInput
}

from 'mdb-react-ui-kit';
import axios from "axios";
import 'react-toastify/dist/ReactToastify.css';

import ipaddress from "../ipaddress";
import confetti from 'canvas-confetti';
// import "../Style.css";
// import "../login.css";
//import '../Style.css';
import rapport from "../../assets/img/image copy.png";
// import AuthContext from "./AuthProvider";
import { Link, useNavigate } from "react-router-dom";
import { Row, Col, Card, Button, Form } from 'react-bootstrap';
import { faHome, faKey, faRedoAlt, faSignInAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ToastContainer, toast } from 'react-toastify';
// import CFSService from "../service/CFSService";
import AuthContext from "../AuthProvider";
import Swal from "sweetalert2";

import RapportSoftlogo from "../../assets/img/image copy.png";
import { useDropzone } from 'react-dropzone';
export default function Login() {

  const { login } = useContext(AuthContext);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('')
  // const [LogInErrors, setLogInErrors] = useState({});
  const [LogInErrors, setLogInErrors] = useState({
    email: '',
    password: ''
  });

  const [errors, setErrors] = useState({});

  const [user, setuser] = useState({
    firstName: "",
    user_Email: "",
    subject: "",
    message: "",
    companyName: "",
    address: "",
    pinCode: "",
    city: "",
    state: "",
    gstNo: "",
    panNo: "",
    tanNo: "",
    mobile: "",
    user_Name: "",
    personDesignation: "",
    user_Password: "",
    file: null,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setuser({
      ...user,
      [name]: value,
    });
    setErrors('');
  };
  const validateForm = () => {
    const newErrors = {};
    if (!user.companyName) newErrors.companyName = 'Company Name is required';
    if (!user.user_Password) newErrors.User_Password = 'Password is required';
    if (!user.address) newErrors.address = 'Address is required';
    if (!user.pinCode) newErrors.pinCode = 'Pin Code is required';
    if (!user.city) newErrors.city = 'City is required';
    if (!user.state) newErrors.state = 'State is required';
    if (!user.gstNo) newErrors.gstNo = 'GST No is required';
    if (!user.panNo) newErrors.panNo = 'PAN No is required';
    if (!user.mobile) newErrors.mobile = 'Contact No is required';
    if (!user.user_Name) newErrors.User_Name = 'Person Name is required';
    if (!user.user_Email
    ) newErrors.User_Email = 'Email is required';
    if (!user.file) newErrors.file = 'File Upload is required';
    return newErrors;
  };

  const handleImageChange = (e) => {
    const imagePath = e.target.files[0];
    if (imagePath) {
      // Validate if the selected file is an image
      const validFileTypes = ['image/', 'application/pdf'];
      if (!validFileTypes.some((type) => imagePath.type.startsWith(type))) {
        alert('Please select an image or PDF file.');
        return;
      }
      // Read the file and set it as the image path
      const reader = new FileReader();
      reader.onload = (event) => {
        setuser((prevEmployee) => ({
          ...prevEmployee,
          file: event.target.result
        }));
      };
      reader.readAsDataURL(imagePath);
    }
  };
  const validateGST = (value) => {
    // GSTIN format validation regex
    const gstRegex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/;

    if (!value.match(gstRegex)) {
      return "Enter a valid GST number";
    }
    return "";
  };

  const validatePAN = (value) => {
    const panPattern = /^[A-Za-z]{5}\d{4}[A-Za-z]{1}$/;
    if (!value.match(panPattern)) {
      return "Enter a valid PAN number";
    }
    return "";
  };

  const validateMobile = (value) => {
    const mobilePattern = /^[6-9]\d{9}$/; // Validates Indian mobile numbers starting with 6-9 and having 10 digits
    if (!value.match(mobilePattern)) {
      return "Enter a valid mobile number";
    }
    return "";
  };

  const validateEmail = (value) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!value.match(emailPattern)) {
      return "Enter a valid email address";
    }
    return "";
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }
    const panError = validatePAN(user.panNo);
    const gstError = validateGST(user.gstNo);
    const mobileError = validateMobile(user.mobile);
    const emailError = validateEmail(user.user_Email);

    if (panError || gstError) {
      setErrors({
        ...errors,
        panNo: panError,
        gstNo: gstError,
        mobile: mobileError,
        user_Email: emailError,
      });
      toast.error('Please fill in all required fields and ensure GST, PAN, Mobile, and Email numbers are valid.', {
        position: 'top-center',
        autoClose: 900,
      });
      return;
    }
    console.log("user", user);

    axios.post(`${ipaddress}user/saveUser`, user)
      .then((response) => {
        const result = response.data;
        console.log(result);
        toast.success('Registration Successful! Congratulations! You have registered successfully.', {
          position: 'top-center',
          autoClose: 900,
        });
        setuser('');
        setErrors('');
        confetti({
          particleCount: 100,
          spread: 70,
          origin: { y: 0.6 }
        });
        navigate('/login');
        setRightPanelActive(false);
      })
      .catch((error) => {
        Swal.fire({
          icon: 'error',
          title: 'Registration Failed',
          text: error.response.data,
          customClass: {
            popup: 'swal2-popup',
            title: 'swal2-title',
            content: 'swal2-content',
            confirmButton: 'swal2-confirm',
            cancelButton: 'swal2-cancel'
          }
        });

      }
      )
  };

  const handleSignIn = async (e) => {
    e.preventDefault();

    const validationErrors = {};

    if (!email) {
      validationErrors.email = 'Email is required';
    }

    if (!password) {
      validationErrors.password = 'Password is required';
    }

    setLogInErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      console.log('email', email);
      console.log('password', password);

      const reactPageName = 'YourReactPageName'; // Replace with your actual React page name
      const url = `${ipaddress}auth/login/${email}/${password}`;
      const jwtRequest = {
        username: email,
        password: password
      };

      try {
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'React-Page-Name': reactPageName
          },
          body: JSON.stringify(jwtRequest)
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
       // newToken, newUserId, newUsername, newBranchId, newCompanyId, newAutoid, newRole, newCompanyname, newBranchname
        const data = await response.json();
        console.log('data ',data);
        login(data.jwtToken,data.userId,data.username,data.branchId,data.companyid,data.autoid,"User",data.companyname,"Rapportsoft"); // Call the login function from AuthContext
        navigate("/")

        confetti({
          particleCount: 2700,
          spread: 700,
          origin: { y: 0.6 }
        });

        toast.success(`Login successful`, {
          position: "top-center",
          autoClose: 900,
        });

        console.log('Login successful:', data);
        // Handle success, such as storing tokens, navigating to the dashboard, etc.

      } catch (error) {
        console.error('Login error:', error.message);
        toast.error(`Login failed`, {
          position: "top-center",
          autoClose: 900,
        });
      }
    }
  };




  const getCurrentYear = () => {
    return new Date().getFullYear();
  };
  const navigate = useNavigate();

  const [rightPanelActive, setRightPanelActive] = useState(false);

  const handleSignUpClick = () => {
    setRightPanelActive(true);
    setuser('');
  };

  const handleSignInClick = () => {
    setRightPanelActive(false);
    setuser('');
  };
  // const Footer = () => {
  //   return (
  //     <footer className="footer mt-auto py-3 bg-dark whiteColor">
  //     <div className="container text-center whiteColor">
  //         <span className="whiteColor">© {new Date().getFullYear()} Your Company. All rights reserved.</span>
  //       </div>
  //     </footer>
  //   );
  // };



  const Footer = () => {
    return (
      <footer className="footer mt-auto py-3">
        <div className="container text-center text-white" style={{ background: 'linear-gradient(to left, #ee7724, #d8363a, #dd3675, #b44593)' }}>
          {/* <span>© {new Date().getFullYear()} Rapportsoft Consulting and Technology Pvt. Ltd. All rights reserved.</span> */}
          <span style={{ color: 'white' }}>
  © {new Date().getFullYear()}-
  <a href="https://rapportsoft.co.in" target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'none' }}>
    Rapportsoft Consulting and Technology Pvt. Ltd
  </a>
  . All rights reserved.
</span>


        </div>
      </footer>
    );
  };

  const [id, setId] = useState('');
  // const [password, setPassword] = useState('');
  const [buttonText, setButtonText] = useState('Submit');
  const [flag, setFlag] = useState(1);
  const [buttonPosition, setButtonPosition] = useState('150px');

  const handleMouseEnter = () => {
    if (id === '' || password === '') {
      f();
      document.getElementById('a').style.border = '3px solid red';
      document.getElementById('b').style.border = '3px solid red';
      setButtonText('Please enter');
    } else {
      document.getElementById('a').style.border = '3px solid green';
      document.getElementById('b').style.border = '3px solid green';
      setButtonText('Ok,Submit');
      setButtonPosition('120px');
    }
  };

  const f = () => {
    if (flag === 1) {
      setButtonPosition('210px');
      setFlag(2);
    } else if (flag === 2) {
      setButtonPosition('80px');
      setFlag(1);
    }
  };


  return (
 <> 
 {/* <div className="outer">
      <h1 style={{ textAlign: 'center' }}>Legend form</h1>
      <p>Enter Id</p>
      <input
        className="in"
        type="text"
        placeholder="Enter id"
        id="a"
        value={id}
        onChange={(e) => setId(e.target.value)}
      />
      <p>Enter Confirm Pass</p>
      <input
        className="in"
        type="password"
        placeholder="Enter password"
        id="b"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <br />
      <input
        type="submit"
        onMouseEnter={handleMouseEnter}
        onClick={() => alert('waaaa')}
        id="bt"
        value={buttonText}
        style={{ position: 'absolute', left: buttonPosition }}
      />
    </div> */}
    <MDBContainer className="my-5 gradient-form">

      <MDBRow>

        <MDBCol col='6' className="mb-5" style={{marginRight:36}}>
          <div className="d-flex flex-column ms-5">

            <div className="text-center" style={{paddingBottom:54}}>
              <img src={RapportSoftlogo}
                style={{width: '270px'}} alt="logo" />
              {/* <h4 className="mt-1 mb-5 pb-1">We are The Lotus Team</h4> */}
            </div>

            {/* <p>Please login to your account</p> */}


            <MDBInput wrapperClass='mb-4' id='form1' 
            type="text"
            placeholder="Email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setLogInErrors({ ...LogInErrors, email: '' });
            }}

            style={{ borderColor: LogInErrors.email ? 'red' : '' }}
          />
          {LogInErrors.email && <p style={{ color: 'red' }}>{LogInErrors.email}</p>}
          

            <MDBInput wrapperClass='mb-4'  id='form2' type='password'
            
            // label='Email address'
            placeholder="Password"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
              setLogInErrors({ ...LogInErrors, password: '' });
            }}
            style={{ borderColor: LogInErrors.email ? 'red' : '' }}
          />
          {LogInErrors.password && <p style={{ color: 'red' }}>{LogInErrors.password}</p>}


 

<div className="text-center pt-1 mb-2">
              <Button outline className="mb-4 w-100 gradient-custom-2" onClick={handleSignIn}>
              Sign in
              </Button>
              <div style={{ marginTop: 2}}>
                <Link to="/">
                  <FontAwesomeIcon icon={faHome} style={{ color: 'red', marginRight: 4 }} />Back To Home
                </Link>
              </div>
            </div>
            <div className="d-flex flex-row align-items-center justify-content-center pb-1 mb-1">
              <p className="mb-0 flex-row" style={{marginLeft:9}}>Don't have an account?</p>
        <Link to="/register">Create Account
        </Link>

            </div>

          </div>

        </MDBCol>

        <MDBCol col='6' className="mb-5" style={{marginLeft:27}}>
          <div className="d-flex flex-column  justify-content-center gradient-custom-2 h-100 mb-4">

            <div className="text-white px-3 py-4 p-md-5 mx-md-4">
              <h4 class="mb-4">We are more than just a company</h4>
              <p className="small mb-0" style={{marginLeft:"9px"}}>
        At Rapportsoft Counsulting And Technology Pvt. Ltd, we transcend borders and elevate standards in the shipping and air cargo domains. Our commitment to excellence drives us to deliver unparalleled logistics solutions worldwide. With a focus on innovation and reliability, we streamline supply chains, ensuring swift and secure transport of goods across continents.
      </p>
            </div>

          </div>

        </MDBCol>

      </MDBRow>

            <br/>
            <br/>
            <br/>
            <br/>            <br/>            <br/>            <br/>            <br/>            <br/>            <br/>
            <Footer/>
    </MDBContainer>
 {/* <footer style={{ textAlign: 'center', padding: '1rem', backgroundColor: '#f8f9fa' }}>
 <p>© {getCurrentYear()} Rapport. All rights reserved.</p>
</footer> */}



</>




  );
}

 
