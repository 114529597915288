
import React, { useContext, useEffect } from "react";
import { Helmet } from "react-helmet";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { NextUIProvider } from '@nextui-org/react';
// Screens
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Landing from "./screens/Landing.jsx";
import Register from "./components/Sections/Register.jsx";
import Login from "./components/Sections/Login.jsx";
import TopNavbar from "./components/Nav/TopNavbar.jsx";
import { AuthProvider } from "./components/AuthProvider.jsx";
import Branch from "./components/Branch.jsx";
import Pricing from "./components/Sections/Pricing.jsx";
import Privacy from "./components/Sections/Privacy.jsx";
import Terms from "./components/Sections/Terms.jsx";
import CancelationAndRefund from "./components/Sections/CancelationAndRefund.jsx";
export default function App() {
  const {
    username,
    logout,
  } = useContext(AuthProvider) || {};



  return (
    <>
      <NextUIProvider>
        <ToastContainer
        />
        <Router>
    
            <AuthProvider>
              {/* <TopNavbar /> */}
              <Routes>
                <Route path="/" element={<Landing />} />
                <Route path="/register" element={<Register />} />
                <Route path="/login" element={<Login />} />
                <Route path="/branch" element={<Branch />} />
                <Route path="/pricing" element={<Pricing />} />
                <Route path="/privacy" element={<Privacy />} />
                <Route path="/terms" element={<Terms />} />
                <Route path="/cancelandrefund" element={<CancelationAndRefund />} />
              </Routes>
            </AuthProvider>
      
        </Router>
        <Helmet>
          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="true" />
          <link href="https://fonts.googleapis.com/css2?family=Khula:wght@400;600;800&display=swap" rel="stylesheet" />
        </Helmet>
      </NextUIProvider>

    </>
  );
}

