import React from "react";
import styled from "styled-components";
import FullButton from "../Buttons/FullButton";
import HeaderImage from "../../assets/img/intro.png";
import svg from "../../assets/img/hero-area.svg";
import QuotesIcon from "../../assets/svg/Quotes";
import Dots from "../../assets/svg/Dots";

export default function Header() {
  return (
    <Wrapper id="home">
      <BackgroundImg src={svg} alt="background" />
      <ContentWrapper className="container flexSpaceCenter">
        <LeftSide>
          <div>
            <h1 className="extraBold font60">Customized Solutions For Business Development.</h1>
            <HeaderP className="font13 semiBold" style={{marginLeft:"9px"}}>
              Rapportsoft Consulting & Technology Pvt. Ltd. is one of India's largest Shipping software products company providing innovative and integrated enterprise solutions ensuring.
            </HeaderP>
            {/* <BtnWrapper>
              <FullButton title="Get Started" style={{ backgroundColor: 'red', color: 'white' }} />
            </BtnWrapper> */}
          </div>
        </LeftSide>
        <RightSide>
          <Img className="radius8 responsive" src={HeaderImage} alt="office" />
        </RightSide>
        {/* <DotsWrapper>
          <Dots />
        </DotsWrapper> */}
      </ContentWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  position: relative;
  padding-top: 130px;
  width: 100%;
  min-height: 840px;

  @media (max-width: 1200px) {
    padding-top: 100px;
  }

  @media (max-width: 992px) {
    padding-top: 100px;
  }

  @media (max-width: 768px) {
    padding-top: 100px;
  }

  @media (max-width: 576px) {
    padding-top: 100px;
  }

  @media (max-width: 480px) {
    padding-top: 100px;
  }

  @media (max-width: 400px) {
    padding-top: 100px;
  }
`;

const ContentWrapper = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  align-items: center;

  @media (max-width: 960px) {
    grid-template-columns: 1fr;
    text-align: center;
  }
`;

const LeftSide = styled.div`
  padding: 0 20px;
`;

const RightSide = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const HeaderP = styled.p`
  max-width: 470px;
  padding: 15px 0 50px 0;
  line-height: 1.5rem;

  @media (max-width: 768px) {
    padding: 15px 0 30px 0;
  }
`;

const BtnWrapper = styled.div`
  max-width: 190px;
  z-index: 11;
`;

const DotsWrapper = styled.div`
  position: absolute;
  right: -100px;
  bottom: 100px;
  z-index: 2;

  @media (max-width: 960px) {
    right: 100px;
  }

  @media (max-width: 560px) {
    display: none;
  }
`;

const BackgroundImg = styled.div`
  position: absolute;
  left: 0;
  top: -100px;
  width: 100%;
  height: 100%;
  background-image: url(${props => props.src});
  background-size: cover;
  background-position: center;
  z-index: 1;

  @media (max-width: 768px) {
    height: auto;
    top: -50px;
  }

  @media (max-width: 480px) {
    height: auto;
    top: 0;
  }

  @media (max-width: 400px) {
    height: auto;
  }
`;

const Img = styled.img`
  width: 100%;
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  position: relative;
  z-index: 2;
`;
