//const ipaddress = '192.168.137.1';
// const ipaddress = '192.168.1.134';
//const ipaddress = "http://192.168.1.129:8080/";
// const ipaddress = "http://13.200.201.230:8181/EYMS/";
//const ipaddress = "http://3.109.44.255:8080/EYMS/";
// const ipaddress = 'http://192.168.1.129:8080/';
const ipaddress = 'https://rapportsoft.in:8443/saas/';
// const ipaddress = 'http://192.168.1.129:8080/';
//const ipaddress = 'http://103.189.88.215:8080/EYMS/';
//const ipaddress = 'https://rapportsoft.in:8443/EYMS/'
export default ipaddress;


