

import React,{ useEffect, useState, useContext } from 'react';
import {
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBInput
}

from 'mdb-react-ui-kit';
import axios from "axios";
import 'react-toastify/dist/ReactToastify.css';
import container1 from "../../assets/img/pxfuel.jpg"
//import ButtonGroup from "react-bootstrap/ButtonGroup";
import Dropdown from "react-bootstrap/Dropdown";
import ipaddress from "../ipaddress";
import confetti from 'canvas-confetti';
// import "../Style.css";
// import "../login.css";
//import '../Style.css';
import rapport from "../../assets/img/image copy.png";
// import AuthContext from "./AuthProvider";
import { Link, useNavigate } from "react-router-dom";
import { Row, Col, Card, Button, Form } from 'react-bootstrap';
import { faHome, faKey, faRedoAlt, faSignInAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ToastContainer, toast } from 'react-toastify';
// import CFSService from "../service/CFSService";
import ButtonGroup from 'react';
import AuthContext from "../AuthProvider";
import Landing from "../../screens/Landing";
import Swal from "sweetalert2";

import RapportSoftlogo from "../../assets/img/image copy.png";
export default function Register() {

  const { login } = useContext(AuthContext);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('')
  // const [LogInErrors, setLogInErrors] = useState({});
  const [LogInErrors, setLogInErrors] = useState({
    email: '',
    password: ''
  });

  const [errors, setErrors] = useState({});

  const [user, setuser] = useState({
    firstName: "",
    user_Email: "",
    subject: "",
    message: "",
    companyName: "",
    address: "",
    pinCode: "",
    city: "",
    state: "",
    gstNo: "",
    panNo: "",
    tanNo: "",
    mobile: "",
    user_Name: "",
    personDesignation: "",
    user_Password: "",
    file: null,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setuser({
      ...user,
      [name]: value,
    });
    setErrors('');
  };
  const validateForm = () => {
    const newErrors = {};
    if (!user.companyName) newErrors.companyName = 'Company Name is required';
    if (!user.user_Password) newErrors.User_Password = 'Password is required';
    if (!user.address) newErrors.address = 'Address is required';
    if (!user.pinCode) newErrors.pinCode = 'Pin Code is required';
    if (!user.city) newErrors.city = 'City is required';
    if (!user.state) newErrors.state = 'State is required';
    if (!user.gstNo) newErrors.gstNo = 'GST No is required';
    if (!user.panNo) newErrors.panNo = 'PAN No is required';
    if (!user.mobile) newErrors.mobile = 'Contact No is required';
    if (!user.user_Name) newErrors.User_Name = 'Person Name is required';
    if (!user.user_Email
    ) newErrors.User_Email = 'Email is required';
    if (!user.file) newErrors.file = 'File Upload is required';
    return newErrors;
  };

  const [states, setStates] = useState([]);
  const [city, setCity] = useState([]);

  const getStates = () => {
    axios.get(`${ipaddress}jar/getData?jarid=ST`)
      .then((response) => {
        setStates(response.data);
      })
      .catch((error) => {

      })
  }

  const getCity = () => {
    axios.get(`${ipaddress}jar/getData?jarid=CTY`)
      .then((response) => {
        setCity(response.data);
      })
      .catch((error) => {

      })
  }
  useEffect(() => {
    getCity();
    getStates();
  }, []);
  
  const handleImageChange = (e) => {
    const imagePath = e.target.files[0];
    if (imagePath) {
      // Validate if the selected file is an image
      const validFileTypes = ['image/', 'application/pdf'];
      if (!validFileTypes.some((type) => imagePath.type.startsWith(type))) {
        alert('Please select an image or PDF file.');
        return;
      }
      // Read the file and set it as the image path
      const reader = new FileReader();
      reader.onload = (event) => {
        setuser((prevEmployee) => ({
          ...prevEmployee,
          file: event.target.result
        }));
      };
      reader.readAsDataURL(imagePath);
    }
  };
  const validateGST = (value) => {
    // GSTIN format validation regex
    const gstRegex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/;

    if (!value.match(gstRegex)) {
      return "Enter a valid GST number";
    }
    return "";
  };

  const validatePAN = (value) => {
    const panPattern = /^[A-Za-z]{5}\d{4}[A-Za-z]{1}$/;
    if (!value.match(panPattern)) {
      return "Enter a valid PAN number";
    }
    return "";
  };

  const validateMobile = (value) => {
    const mobilePattern = /^[6-9]\d{9}$/; // Validates Indian mobile numbers starting with 6-9 and having 10 digits
    if (!value.match(mobilePattern)) {
      return "Enter a valid mobile number";
    }
    return "";
  };

  const validateEmail = (value) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!value.match(emailPattern)) {
      return "Enter a valid email address";
    }
    return "";
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }
    const panError = validatePAN(user.panNo);
    const gstError = validateGST(user.gstNo);
    const mobileError = validateMobile(user.mobile);
    const emailError = validateEmail(user.user_Email);

    if (panError || gstError) {
      setErrors({
        ...errors,
        panNo: panError,
        gstNo: gstError,
        mobile: mobileError,
        user_Email: emailError,
      });
      toast.error('Please fill in all required fields and ensure GST, PAN, Mobile, and Email numbers are valid.', {
        position: 'top-center',
        autoClose: 900,
      });
      return;
    }
    console.log("user", user);

    axios.post(`${ipaddress}user/saveUser`, user)
      .then((response) => {
        const result = response.data;
        console.log(result);
        toast.success('Registration Successful! Congratulations! You have registered successfully.', {
          position: 'top-center',
          autoClose: 900,
        });
        setuser('');
        setErrors('');
        confetti({
          particleCount: 100,
          spread: 70,
          origin: { y: 0.6 }
        });
        navigate('/login');
      })
      .catch((error) => {
        Swal.fire({
          icon: 'error',
          title: 'Registration Failed',
          text: error.response.data,
          customClass: {
            popup: 'swal2-popup',
            title: 'swal2-title',
            content: 'swal2-content',
            confirmButton: 'swal2-confirm',
            cancelButton: 'swal2-cancel'
          }
        });

      }
      )
  };

  const getCurrentYear = () => {
    return new Date().getFullYear();
  };
  const navigate = useNavigate();
  // const Footer = () => {
  //   return (
  //     <footer className="footer mt-auto py-3 bg-light">
  //       <div className="container text-center">
  //         <span className="text-muted">© {new Date().getFullYear()} Your Company. All rights reserved.</span>
  //       </div>
  //     </footer>
  //   );
  // };





  const Footer = () => {
    return (
      <footer className="footer mt-auto py-3">
        <div className="container text-center text-white" style={{ background: 'linear-gradient(to left, #ee7724, #d8363a, #dd3675, #b44593)' }}>
          {/* <span>© {new Date().getFullYear()} Rapportsoft Consulting and Technology Pvt. Ltd. All rights reserved.</span> */}
          <span style={{ color: 'white' }}>
  © {new Date().getFullYear()}- 
  <a href="https://rapportsoft.co.in" target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'none' }}>
    Rapportsoft Consulting and Technology Pvt. Ltd
  </a>
  . All rights reserved.
</span>
        </div>
      </footer>
    );
  };
  return (
    <>
    <MDBContainer className="my-5 gradient-form">
    <MDBRow>
        <MDBCol col='6' className="mb-5" style={{marginRight:36}}>
          <div className="d-flex flex-column ms-5">
            <div className="text-center" style={{ paddingBottom: 54 }}>
              <img src={RapportSoftlogo} style={{ width: '270px' }} alt="logo" />
            </div>

            <MDBInput wrapperClass='mb-4'  id='form2' type="text" name="companyName" placeholder="Company Name *" value={user.companyName} onChange={handleChange} />
            {errors.companyName && <span style={{ color: 'red' }}>{errors.companyName}</span>}

            <MDBInput wrapperClass='mb-4'  id='form2' type="text" name="address" placeholder="Address *" value={user.address} onChange={handleChange} />
            {errors.address && <span style={{ color: 'red' }}>{errors.address}</span>}

            <MDBInput wrapperClass='mb-4'  id='form2' type="text" name="pinCode" placeholder="Pin Code *" value={user.pinCode} onChange={handleChange} />
            {errors.pinCode && <span style={{ color: 'red' }}>{errors.pinCode}</span>} 
            <select
  className="form-select mb-4"
  id="stateSelect"
  name="state"
  value={user.state}
  onChange={handleChange}
>
  <option value="">Select State</option>
  {states.map((state,index) => (
    <option key={index} value={state.jarDtlId}>
      {state.jarDtlDesc}
    </option>
  ))}
</select>

{errors.state && <span style={{ color: 'red' }}>{errors.state}</span>}

{/* 
            <MDBInput wrapperClass='mb-4'  id='form2' type="text" name="city" placeholder="City *" value={user.city} onChange={handleChange} /> */}

<select
  className="form-select mb-4"
  id="stateSelect"
  name="city"
  value={user.city}
  onChange={handleChange}
>
  <option value="">Select City</option>
  {city.map((state,index) => (
    <option key={index} value={state.jarDtlId}>
      {state.jarDtlDesc}
    </option>
  ))}
</select>
            {errors.city && <span style={{ color: 'red' }}>{errors.city}</span>}

            {/* <MDBInput wrapperClass='mb-4'  id='form2' type="text" name="state" placeholder="State *" value={user.state} onChange={handleChange} />
            {errors.state && <span style={{ color: 'red' }}>{errors.state}</span>} */}



            

            <MDBInput wrapperClass='mb-4'  id='form2' type="text" name="gstNo" placeholder="GST No *" value={user.gstNo} onChange={handleChange} />
            {errors.gstNo && <span style={{ color: 'red' }}>{errors.gstNo}</span>}

            <MDBInput wrapperClass='mb-4'  id='form2' type="text" name="panNo" placeholder="PAN No *" value={user.panNo} onChange={handleChange} />
            {errors.panNo && <span style={{ color: 'red' }}>{errors.panNo}</span>}

            <MDBInput wrapperClass='mb-4'  id='form2' type="text" name="tanNo" placeholder="TAN No" value={user.tanNo} onChange={handleChange} />

            <MDBInput wrapperClass='mb-4'  id='form2' type="text" name="mobile" placeholder="Contact No *" value={user.mobile} onChange={handleChange} />
            {errors.mobile && <span style={{ color: 'red' }}>{errors.mobile}</span>}

            <MDBInput wrapperClass='mb-4'  id='form2' type="text" name="user_Name" placeholder="Person Name *" value={user.user_Name} onChange={handleChange} />
            {errors.User_Name && <span style={{ color: 'red' }}>{errors.User_Name}</span>}

            <MDBInput wrapperClass='mb-4'  id='form2' type="text" name="personDesignation" placeholder="Person Designation" value={user.personDesignation} onChange={handleChange} />

            <MDBInput wrapperClass='mb-4'  id='form2' type="email" name="user_Email" placeholder="Email *" value={user.user_Email} onChange={handleChange} />
            {errors.User_Email && <span style={{ color: 'red' }}>{errors.User_Email}</span>}

            <MDBInput wrapperClass='mb-4'  id='form2' type="file" name="file" placeholder="Upload File *" accept="image/*,application/pdf" onChange={handleImageChange} className="custom-file-input" />
            {errors.file && <span style={{ color: 'red' }}>{errors.file}</span>}

            <MDBInput wrapperClass='mb-4'  id='form2' type="password" name="user_Password" placeholder="Password *" value={user.user_Password} onChange={handleChange} />
            {errors.User_Password && <span style={{ color: 'red' }}>{errors.User_Password}</span>}

            <div className="text-center pt-1 mb-2">
              <Button outline className="mb-4 w-100 gradient-custom-2" onClick={handleSubmit}>
                Sign Up
              </Button>

              <div className="d-flex flex-row align-items-center justify-content-center pb-1 mb-1">
              <p className="mb-0" style={{marginLeft:9}}>already have an account?</p>
               <div >&nbsp;
        <Link to="/login">log In
        </Link>
</div></div>
                <Link to="/">
                  <FontAwesomeIcon icon={faHome} style={{ color: 'red', marginRight: 4 }} />Back To Home
                </Link>

            </div>
          </div>
        </MDBCol>

        <MDBCol col='6' className="mb-5" style={{marginLeft:27}}>
          <div className="d-flex flex-column justify-content-center gradient-custom-2 h-100 mb-4">
            <div className="text-white px-3 py-4 p-md-5 mx-md-4">
              <h4 className="mb-4">We are more than just a company</h4>
              <p className="small mb-0" style={{marginLeft:"9px"}}>
        At Rapportsoft Counsulting And Technology Pvt. Ltd, we transcend borders and elevate standards in the shipping and air cargo domains. Our commitment to excellence drives us to deliver unparalleled logistics solutions worldwide. With a focus on innovation and reliability, we streamline supply chains, ensuring swift and secure transport of goods across continents.
      </p>
            </div>
          </div>
        </MDBCol>
      </MDBRow>
      <Footer/>
    </MDBContainer>
 </>
  );
}
