
import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import PricingTable from "../Elements/PricingTable";
import AuthContext from "../AuthProvider";
import useAxios from "../Sections/useAxios"
import ipaddress from "../ipaddress"
import { toast } from "react-toastify";
import CloseButton from 'react-bootstrap/CloseButton';
import { CheckboxIcon } from "@nextui-org/react";
import Spinner from 'react-bootstrap/Spinner';
import Swal from 'sweetalert2';
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Button,
  Label,
  Input,
  Table,
} from "reactstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../Style1.css"
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useLocation, useNavigate } from "react-router-dom";
import { Tabs, Tab } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faMoneyBill, faPlus, faTrash, faUser } from "@fortawesome/free-solid-svg-icons";
export default function Pricing({ status }) {

  const axios = useAxios();

  const Checkmark = () => (
    <div style={{ fontSize: '20px', lineHeight: '20px', textAlign: 'center' }}>
      ✓
    </div>
  );

  const CloseMark = () => (
    <div style={{ fontSize: '20px', lineHeight: '20px', textAlign: 'center' }}>
      X
    </div>
  );
  const {
    jwtToken,
    userId,
    username,
    branchId,
    companyid,
    role,
    companyname,
    branchname,
    login,
    logout,
  } = useContext(AuthContext);
  const [pricingType, setPricingType] = useState("EYMS");
  const [priceData, setPriceData] = useState([]);
  const [eymsPricing, setEymsPricing] = useState([])
  const [bwmsPricing, setBwmsPricing] = useState([])

  const getPricing = () => {
    axios.get(`${ipaddress}pricing/getPricing`, {
      headers: {
        'Authorization': `Bearer ${jwtToken}` // Replace authToken with your actual token variable
      }
    })
      .then((response) => {
        setPriceData(response.data);
        console.log('response.data ', response.data);
        setEymsPricing([
          {
            icon: "roller",
            price: `Rs ${response.data[0].price}`,
            actualPrice: response.data[0].price,
            title: "EYMS Starter",
            id: response.data[0].id,
            user: response.data[0].user,
            planType: response.data[0].type,
            type: "starter",
            text: "Get started with our Starter Pack for small businesses and startups. Enjoy essential features at $29.99/month.",
            offers: [
              { name: `Users : ${response.data[0].user}`, checked: true },
              { name: "Product Offer", checked: true },
              { name: "Offer", checked: true },
              { name: "Product Offer #2", checked: false },
              { name: "Product", checked: false },
              { name: "Product Offer", checked: false },
            ],
          },
          {
            icon: "monitor",
            price: `Rs ${response.data[1].price}`,
            actualPrice: response.data[1].price,
            title: " EYMS Basic",
            id: response.data[1].id,
            user: response.data[1].user,
            planType: response.data[1].type,
            type: "biggier",
            text: "More features and flexibility for growing businesses at $49.99/month.",
            offers: [
              { name: `Users : ${response.data[1].user}`, checked: true },
              { name: "Product Offer", checked: true },
              { name: "Offer", checked: true },
              { name: "Product Offer #2", checked: true },
              { name: "Product", checked: true },
              { name: "Product Offer", checked: false },
            ],
          },
          {
            icon: "browser",
            price: `Rs ${response.data[2].price}`,
            actualPrice: response.data[2].price,
            title: "EYMS Golden",
            type: "golden",
            id: response.data[2].id,
            user: response.data[2].user,
            planType: response.data[2].type,
            text: "Unlock advanced features for large enterprises at $59.99/month.",
            offers: [
              { name: `Users : ${response.data[2].user}`, checked: true },
              { name: "Product Offer", checked: true },
              { name: "Offer", checked: true },
              { name: "Product Offer #2", checked: true },
              { name: "Product", checked: true },
              { name: "Product Offer", checked: true },
            ],
          },
          {
            icon: "roller",
            price: `Rs ${response.data[3].price}`,
            actualPrice: response.data[3].price,
            title: "EYMS Starter",
            id: response.data[3].id,
            user: response.data[3].user,
            planType: response.data[3].type,
            type: "starter",
            text: "Get started with our Starter Pack for small businesses and startups. Enjoy essential features at $29.99/month.",
            offers: [
              { name: `Users : ${response.data[3].user}`, checked: true },
              { name: "Product Offer", checked: true },
              { name: "Offer", checked: true },
              { name: "Product Offer #2", checked: false },
              { name: "Product", checked: false },
              { name: "Product Offer", checked: false },
            ],
          },
          {
            icon: "monitor",
            price: `Rs ${response.data[4].price}`,
            actualPrice: response.data[4].price,
            title: " EYMS Basic",
            id: response.data[4].id,
            user: response.data[4].user,
            planType: response.data[4].type,
            type: "biggier",
            text: "More features and flexibility for growing businesses at $49.99/month.",
            offers: [
              { name: `Users : ${response.data[4].user}`, checked: true },
              { name: "Product Offer", checked: true },
              { name: "Offer", checked: true },
              { name: "Product Offer #2", checked: true },
              { name: "Product", checked: true },
              { name: "Product Offer", checked: false },
            ],
          },
          {
            icon: "browser",
            price: `Rs ${response.data[5].price}`,
            actualPrice: response.data[5].price,
            title: "EYMS Golden",
            type: "golden",
            id: response.data[5].id,
            user: response.data[5].user,
            planType: response.data[5].type,
            text: "Unlock advanced features for large enterprises at $59.99/month.",
            offers: [
              { name: `Users : ${response.data[5].user}`, checked: true },
              { name: "Product Offer", checked: true },
              { name: "Offer", checked: true },
              { name: "Product Offer #2", checked: true },
              { name: "Product", checked: true },
              { name: "Product Offer", checked: true },
            ],
          },
          {
            icon: "roller",
            price: `Rs ${response.data[6].price}`,
            actualPrice: response.data[6].price,
            title: "EYMS Starter",
            id: response.data[6].id,
            user: response.data[6].user,
            planType: response.data[6].type,
            type: "starter",
            text: "Get started with our Starter Pack for small businesses and startups. Enjoy essential features at $29.99/month.",
            offers: [
              { name: `Users : ${response.data[6].user}`, checked: true },
              { name: "Product Offer", checked: true },
              { name: "Offer", checked: true },
              { name: "Product Offer #2", checked: false },
              { name: "Product", checked: false },
              { name: "Product Offer", checked: false },
            ],
          },
          {
            icon: "monitor",
            price: `Rs ${response.data[7].price}`,
            actualPrice: response.data[7].price,
            title: " EYMS Basic",
            id: response.data[7].id,
            user: response.data[7].user,
            planType: response.data[7].type,
            type: "biggier",
            text: "More features and flexibility for growing businesses at $49.99/month.",
            offers: [
              { name: `Users : ${response.data[7].user}`, checked: true },
              { name: "Product Offer", checked: true },
              { name: "Offer", checked: true },
              { name: "Product Offer #2", checked: true },
              { name: "Product", checked: true },
              { name: "Product Offer", checked: false },
            ],
          },
          {
            icon: "browser",
            price: `Rs ${response.data[8].price}`,
            actualPrice: response.data[8].price,
            title: "EYMS Golden",
            type: "golden",
            id: response.data[8].id,
            user: response.data[8].user,
            planType: response.data[8].type,
            text: "Unlock advanced features for large enterprises at $59.99/month.",
            offers: [
              { name: `Users : ${response.data[8].user}`, checked: true },
              { name: "Product Offer", checked: true },
              { name: "Offer", checked: true },
              { name: "Product Offer #2", checked: true },
              { name: "Product", checked: true },
              { name: "Product Offer", checked: true },
            ],
          },
        ])
        setBwmsPricing([
          {
            icon: "roller",
            price: `Rs ${response.data[9].price}`,
            actualPrice: response.data[9].price,
            title: "BWMS Starter",
            type: "starter",
            id: response.data[9].id,
            user: response.data[9].user,
            planType: response.data[9].type,
            text: "Different pricing text for BWMS Starter plan.",
            offers: [
              { name: `Users : ${response.data[9].user}`, checked: true },
              { name: "BWMS Product Offer", checked: true },
              { name: "BWMS Offer", checked: true },
              { name: "BWMS Product Offer #2", checked: false },
              { name: "BWMS Product", checked: false },
              { name: "BWMS Product Offer", checked: false },
            ],
          },
          {
            icon: "monitor",
            price: `Rs ${response.data[10].price}`,
            actualPrice: response.data[10].price,
            title: "BWMS Basic",
            type: "bigginer",
            id: response.data[10].id,
            user: response.data[10].user,
            planType: response.data[10].type,
            text: "Different pricing text for BWMS Basic plan.",
            offers: [
              { name: `Users : ${response.data[10].user}`, checked: true },
              { name: "BWMS Product Offer", checked: true },
              { name: "BWMS Offer", checked: true },
              { name: "BWMS Product Offer #2", checked: true },
              { name: "BWMS Product", checked: true },
              { name: "BWMS Product Offer", checked: false },
            ],
          },
          {
            icon: "browser",
            price: `Rs ${response.data[11].price}`,
            actualPrice: response.data[11].price,
            title: "BWMS Golden",
            type: "golden",
            id: response.data[11].id,
            user: response.data[11].user,
            planType: response.data[11].type,
            text: "Different pricing text for BWMS Golden plan.",
            offers: [
              { name: `Users : ${response.data[11].user}`, checked: true },
              { name: "BWMS Product Offer", checked: true },
              { name: "BWMS Offer", checked: true },
              { name: "BWMS Product Offer #2", checked: true },
              { name: "BWMS Product", checked: true },
              { name: "BWMS Product Offer", checked: true },
            ],
          },
          {
            icon: "roller",
            price: `Rs ${response.data[12].price}`,
            actualPrice: response.data[12].price,
            title: "BWMS Starter",
            type: "starter",
            id: response.data[12].id,
            user: response.data[12].user,
            planType: response.data[12].type,
            text: "Different pricing text for BWMS Starter plan.",
            offers: [
              { name: `Users : ${response.data[12].user}`, checked: true },
              { name: "BWMS Product Offer", checked: true },
              { name: "BWMS Offer", checked: true },
              { name: "BWMS Product Offer #2", checked: false },
              { name: "BWMS Product", checked: false },
              { name: "BWMS Product Offer", checked: false },
            ],
          },
          {
            icon: "monitor",
            price: `Rs ${response.data[13].price}`,
            actualPrice: response.data[13].price,
            title: "BWMS Basic",
            type: "bigginer",
            id: response.data[13].id,
            user: response.data[13].user,
            planType: response.data[13].type,
            text: "Different pricing text for BWMS Basic plan.",
            offers: [
              { name: `Users : ${response.data[13].user}`, checked: true },
              { name: "BWMS Product Offer", checked: true },
              { name: "BWMS Offer", checked: true },
              { name: "BWMS Product Offer #2", checked: true },
              { name: "BWMS Product", checked: true },
              { name: "BWMS Product Offer", checked: false },
            ],
          },
          {
            icon: "browser",
            price: `Rs ${response.data[14].price}`,
            actualPrice: response.data[14].price,
            title: "BWMS Golden",
            type: "golden",
            id: response.data[14].id,
            user: response.data[14].user,
            planType: response.data[14].type,
            text: "Different pricing text for BWMS Golden plan.",
            offers: [
              { name: `Users : ${response.data[14].user}`, checked: true },
              { name: "BWMS Product Offer", checked: true },
              { name: "BWMS Offer", checked: true },
              { name: "BWMS Product Offer #2", checked: true },
              { name: "BWMS Product", checked: true },
              { name: "BWMS Product Offer", checked: true },
            ],
          },
          {
            icon: "roller",
            price: `Rs ${response.data[15].price}`,
            actualPrice: response.data[15].price,
            title: "BWMS Starter",
            type: "starter",
            id: response.data[15].id,
            user: response.data[15].user,
            planType: response.data[15].type,
            text: "Different pricing text for BWMS Starter plan.",
            offers: [
              { name: `Users : ${response.data[15].user}`, checked: true },
              { name: "BWMS Product Offer", checked: true },
              { name: "BWMS Offer", checked: true },
              { name: "BWMS Product Offer #2", checked: false },
              { name: "BWMS Product", checked: false },
              { name: "BWMS Product Offer", checked: false },
            ],
          },
          {
            icon: "monitor",
            price: `Rs ${response.data[16].price}`,
            actualPrice: response.data[16].price,
            title: "BWMS Basic",
            type: "bigginer",
            id: response.data[16].id,
            user: response.data[16].user,
            planType: response.data[16].type,
            text: "Different pricing text for BWMS Basic plan.",
            offers: [
              { name: `Users : ${response.data[16].user}`, checked: true },
              { name: "BWMS Product Offer", checked: true },
              { name: "BWMS Offer", checked: true },
              { name: "BWMS Product Offer #2", checked: true },
              { name: "BWMS Product", checked: true },
              { name: "BWMS Product Offer", checked: false },
            ],
          },
          {
            icon: "browser",
            price: `Rs ${response.data[17].price}`,
            actualPrice: response.data[17].price,
            title: "BWMS Golden",
            type: "golden",
            id: response.data[17].id,
            user: response.data[17].user,
            planType: response.data[17].type,
            text: "Different pricing text for BWMS Golden plan.",
            offers: [
              { name: `Users : ${response.data[17].user}`, checked: true },
              { name: "BWMS Product Offer", checked: true },
              { name: "BWMS Offer", checked: true },
              { name: "BWMS Product Offer #2", checked: true },
              { name: "BWMS Product", checked: true },
              { name: "BWMS Product Offer", checked: true },
            ],
          },
        ])
      })
      .catch(() => {

      })
  }

  useEffect(() => {
    getPricing();
  }, [])




  const pricingData = pricingType === "EYMS" ? eymsPricing : bwmsPricing;
  const navigate = useNavigate();

  React.useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://checkout.razorpay.com/v1/checkout.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const [loading1, setLoading1] = useState(false);
  const getPayment = (amt, id, planType) => {
    setLoading1(true);
    if (!jwtToken) {
      toast.warn("Please register or login first to proceed.", {
        position: 'top-center',

      });
      navigate('/login');
      setLoading1(false);
      return;
    }
    if (!selectBranch) {
      toast.error("Please select the branch!!", {
        autoClose: 800,
        position: 'top-center',
      });
      setLoading1(false);
      return;
    }

    axios.post(`${ipaddress}pricing/getPrice?amt=${amt}`, null, {
      headers: {
        'Authorization': `Bearer ${jwtToken}`
      }
    })
      .then((response) => {
        const data = response.data;

        if (typeof window.Razorpay === 'undefined') {
          toast.error("Razorpay SDK not loaded", {
            autoClose: 1000,
            position: 'top-center',
          });
          setLoading1(false);
          return;
        }

        let options = {
          key: 'rzp_live_UobKMQTKgrRYbN',
          amount: data.amount,
          currency: 'INR',
          name: 'RapportSoft',
          description: 'testing',
          image: 'https://rapportsoft.co.in/img/rapportlogo1.png',
          order_id: data.id,
          handler: function (response) {
            setLoading1(false);
            console.log(response.razorpay_payment_id);
            console.log(response.razorpay_order_id);
            console.log(response.razorpay_signature);
            console.log('response response ', response);
            toast.success("Payment done!!", {
              autoClose: 1000,
              position: 'top-center',
            });
            saveTransaction(response.razorpay_payment_id, response.razorpay_order_id, id, planType);
          },
          prefill: {
            name: "Sanket Ghodake",
            email: "sanketghodake316@gmail.com",
            contact: "9834795651"
          },
          theme: {
            color: '#FF0000'
          },
          method: {
            netbanking: true,
            card: true,
            upi: true,
            wallet: true,
            emi: true,
          },
          upi: {
            flow: 'collect',
            vpa: '9834795651@paytm'
          },
        };

        setLoading1(false);
        let rzp = new window.Razorpay(options);
        rzp.on('payment.failed', function (response) {
          console.error(response.error);
          const error = response.error;
          if(error.metadata.payment_id != undefined){
            saveTransaction1(error.metadata.payment_id, error.metadata.order_id, id, planType,error.code,error.description);
          }
          
          toast.error(`Payment failed: ${response.error.description}`,{
            autoClose:2000,
            position: 'top-center',
          });
        });
        rzp.open();
      })
      .catch((error) => {
        setLoading1(false);
        console.error(error);
        setLoading1(false);
        toast.error("Payment initiation failed", {
          autoClose: 1000,
          position: 'top-center',
        });
      });
  };


  const saveTransaction = (paymentid, orderid, id, planType) => {
    axios.post(`${ipaddress}transHistory/saveTransaction?cid=${companyid}&bid=${selectBranch}&paymenyid=${paymentid}&orderid=${orderid}&planid=${id}&planType=${planType}`, null, {
      headers: {
        'Authorization': `Bearer ${jwtToken}` // Replace authToken with your actual token variable
      }
    })
      .then((response) => {
        if (response.data === 'success') {
          getBranch();
          handleEdit1(selectBranch);

        }
      })
      .catch((error) => {

      })
  }

  const saveTransaction1 = (paymentid, orderid, id, planType,code,desc) => {
    axios.post(`${ipaddress}transHistory/saveErrorTransaction?cid=${companyid}&bid=${selectBranch}&paymenyid=${paymentid}&orderid=${orderid}&planid=${id}&planType=${planType}&code=${code}&desc=${desc}`, null, {
      headers: {
        'Authorization': `Bearer ${jwtToken}` // Replace authToken with your actual token variable
      }
    })
      .then((response) => {
        if (response.data === 'success') {
          getBranch();
          handleEdit1(selectBranch);

        }
      })
      .catch((error) => {

      })
  }

  const [branch, setBranch] = useState([]);
  const [selectBranch, setSelectBranch] = useState('');

  const getBranch = () => {
    if (username) {
      axios.get(`${ipaddress}transHistory/getBranches?cid=${companyid}`, {
        headers: {
          'Authorization': `Bearer ${jwtToken}` // Replace authToken with your actual token variable
        }
      })
        .then((response) => {
          setBranch(response.data);
        })
        .catch((error) => {

        })
    }
  }
  console.log('status1 ', status);
  useEffect(() => {
    console.log('status2 ', status);
    getBranch();
  }, [])

  useEffect(() => {
    console.log('status3 ', status);
    if (status === 'close') {

      getBranch();
    }
  }, [status]);

  // if (status === 'close') {
  //   getBranch();
  // }

  const handleSelectBranch = (e) => {
    setSelectBranch(e.target.value);
    handleEdit1(e.target.value);
  }

  const [branchtate, setBranchState] = useState({});

  const handleEdit1 = (bid) => {
    axios.get(`${ipaddress}branch/getBranchById?cid=${companyid}&bid=${bid}`, {
      headers: {
        'Authorization': `Bearer ${jwtToken}`
      }
    })
      .then((response) => {
        setBranchState(response.data);
      })
      .catch((error) => {

      })
  }

  const [type, setType] = useState('Month');

  const [plans, setPlans] = useState([]);
  const [planDtls, setPlanDtls] = useState({});

  const getAllPlans = () => {
    axios.get(`${ipaddress}pricingdtl/getPlanDtlData`)
      .then((response) => {
        setPlans(response.data);
      })
      .catch((error) => {
        console.error('Error fetching all plans:', error);
      });
  }

  const getPlanDtls = () => {
    axios.get(`${ipaddress}pricingdtl/getPricingData?type=${pricingType}`)
      .then((response) => {
        console.log('data ', response.data);
        setPlanDtls(response.data);
      })
      .catch((error) => {
        console.error('Error fetching plan details:', error);
      });
  }

  useEffect(() => {
    getAllPlans();
    getPlanDtls();
  }, []);

  const getRowData = (index) => {
    const rowData = [];
    for (const key in planDtls) {
      if (planDtls[key][index]) {
        rowData.push(JSON.stringify(planDtls[key][index]));
      } else {
        rowData.push('');
      }
    }
    return rowData;
  };

  const getMaxRows = () => {
    let maxRows = 0;
    for (const key in planDtls) {
      if (planDtls[key].length > maxRows) {
        maxRows = planDtls[key].length;
      }
    }
    return maxRows;
  };

  const maxRows = getMaxRows();

  const [getPrice, setGetPrice] = useState([]);
  const getPricingData = (pricingType, type) => {
    axios.get(`${ipaddress}pricingdtl/getPricingDataByType?type=${pricingType}&duration=${type}`)
      .then((response) => {
        setGetPrice(response.data);
      })
      .catch((error) => {

      })
  }


  useEffect(() => {
    getPricingData(pricingType, type);
  }, [])

















  const [formState, setFormState] = useState({
    companyId: '',
    branchId: '',
    userId: '',
    autoId: '',
    planId: '',
    username: '',
    password: '',
    encodedPassword: '',
    email: '',
    mobile: '',
    createdBy: '',
    createdDate: null, // This should be a Date object
    editedBy: '',
    editedDate: null, // This should be a Date object
  });

  const [flag, setFlag] = useState('add');

  const [isModalOpenForAddUser, setIsModalOpenForAddUser] = useState(false);
  const [user, setUser] = useState('');

  const handleOpenModal = (planId, user) => {
    setIsModalOpenForAddUser(true)
    getUserData(planId);
    setPlanId(planId);
    setUser(user);
  }

  const closeModal = () => {
    setIsModalOpenForAddUser(false);
    setFormState({
      companyId: '',
      branchId: '',
      userId: '',
      autoId: '',
      username: '',
      password: '',
      encodedPassword: '',
      planId: '',
      email: '',
      mobile: '',
      createdBy: '',
      createdDate: null, // This should be a Date object
      editedBy: '',
      editedDate: null, // This should be a Date object
    })
    setFlag('add');
    setFormErrors({
      userId: '',
      username: '',
      password: '',
      email: '',
      mobile: ''
    })
    document.getElementById('userId').classList.remove('error-border');
    document.getElementById('username').classList.remove('error-border');
    document.getElementById('password').classList.remove('error-border');
    document.getElementById('email').classList.remove('error-border');
    document.getElementById('mobile').classList.remove('error-border');
    setPlanId('');
    setUser('');
  }

  function handleInputChange(e) {
    const inputValue = e;
    const numericInput = inputValue.replace(/[^0-9.]/g, '');
    const parts = numericInput.split('.');
    const integerPart = parts[0];
    let decimalPart = parts[1];

    // Limit decimal places if needed
    if (decimalPart !== undefined) {
      decimalPart = `.${decimalPart.slice(0, 2)}`;
    }

    const sanitizedInput = decimalPart !== undefined ? `${integerPart}${decimalPart}` : integerPart;
    return sanitizedInput;
  };

  const validateMobile = (mobileNo) => /^[6-9][0-9]{9}$/.test(mobileNo);
  const validateEmail = (email) => /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'mobile') {

      setFormState((prevState) => ({
        ...prevState,
        [name]: handleInputChange(value),
      }));
    }
    else {
      const { name, value } = e.target;
      setFormState((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }

  };

  const [planId, setPlanId] = useState('');
  const [formErrors, setFormErrors] = useState({
    userId: '',
    username: '',
    password: '',
    email: '',
    mobile: ''
  })


  const handleClear = () => {
    setFormErrors({
      userId: '',
      username: '',
      password: '',
      email: '',
      mobile: ''
    })

    setFormState({
      companyId: '',
      branchId: '',
      userId: '',
      autoId: '',
      username: '',
      password: '',
      encodedPassword: '',
      email: '',
      planId: '',
      mobile: '',
      createdBy: '',
      createdDate: null, // This should be a Date object
      editedBy: '',
      editedDate: null, // This should be a Date object
    })
    setFlag('add');
    document.getElementById('userId').classList.remove('error-border');
    document.getElementById('username').classList.remove('error-border');
    document.getElementById('password').classList.remove('error-border');
    document.getElementById('email').classList.remove('error-border');
    document.getElementById('mobile').classList.remove('error-border');
  }

  const [loading, setLoading] = useState(false);

  const handleSave = () => {
    setLoading(true);
    setFormErrors({
      userId: '',
      username: '',
      password: '',
      email: '',
      mobile: ''
    })
    document.getElementById('userId').classList.remove('error-border');
    document.getElementById('username').classList.remove('error-border');
    document.getElementById('password').classList.remove('error-border');
    document.getElementById('email').classList.remove('error-border');
    document.getElementById('mobile').classList.remove('error-border');
    const submitBtn = document.getElementById("submitbtn");
    submitBtn.disabled = true;
    let errors = {};

    if (!formState.userId) {
      errors.userId = "User id is required."
      document.getElementById('userId').classList.add('error-border');
    }
    if (!formState.username) {
      errors.username = "Username is required."
      document.getElementById('username').classList.add('error-border');
    }
    if (!formState.password) {
      errors.password = "Password is required."
      document.getElementById('password').classList.add('error-border');
    }
    if (!formState.email) {
      errors.email = "Email id is required."
      document.getElementById('email').classList.add('error-border');
    }
    else {
      if (!validateEmail(formState.email)) {
        errors.email = "Invalid email id."
        document.getElementById('email').classList.add('error-border');
      }
    }
    if (!formState.mobile) {
      errors.mobile = "Mobile no is required."
      document.getElementById('mobile').classList.add('error-border');
    }
    else {
      if (!validateMobile(formState.mobile)) {
        errors.mobile = "Invalid mobile no."
        document.getElementById('mobile').classList.add('error-border');
      }
    }
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      submitBtn.disabled = false;
      setLoading(false);
      return;
    }

    axios.post(`${ipaddress}userdata/saveData?cid=${companyid}&bid=${selectBranch}&user=${userId}&flag=${flag}&planId=${planId}&type=${pricingType}`, formState, {
      headers: {
        'Authorization': `Bearer ${jwtToken}`
      }
    })
      .then((response) => {
        setFormState(response.data);
        toast.success("Data save successfully!!", {
          autoClose: 800,
          position: 'top-center',
        })
        setLoading(false);
        submitBtn.disabled = false;
        getUserData(planId);
        handleClear();
      })
      .catch((error) => {
        toast.error(error.response.data, {
          autoClose: 800,
          position: 'top-center',
        })
        setLoading(false);
        submitBtn.disabled = false;
      })

  }

  const [userData, setUserData] = useState([]);

  const getUserData = (planId) => {
    axios.get(`${ipaddress}userdata/getData?cid=${companyid}&bid=${selectBranch}&plan=${planId}`, {
      headers: {
        'Authorization': `Bearer ${jwtToken}`
      }
    })
      .then((response) => {
        setUserData(response.data);
      })
      .catch((error) => {

      })
  }

  const handleEdit = (id) => {
    axios.get(`${ipaddress}userdata/getDataById?cid=${companyid}&bid=${selectBranch}&auto=${id}`, {
      headers: {
        'Authorization': `Bearer ${jwtToken}`
      }
    })
      .then((response) => {
        setFormState(response.data);
        setFlag('edit');
      })
      .catch((error) => {

      })
  }

  const handleDelete = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      html: `Are you sure you want to delete the record?`,
      icon: 'warning',
      background: '#ffffff',
      showCancelButton: true,
      customClass: {
        icon: 'icon-smaller', // Apply the custom class to the icon
        popup: 'custom-swal-popup',
        title: 'custom-swal-title',
      },
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, close it!'
    }).then((result) => {
      if (result.isConfirmed) {
        axios.post(`${ipaddress}userdata/deleteData?cid=${companyid}&bid=${selectBranch}&auto=${id}&type=${pricingType}`, null, {
          headers: {
            'Authorization': `Bearer ${jwtToken}`
          }
        })
          .then((response) => {
            if (response.data === 'success') {
              toast.error("Data deleted successfully!!", {
                autoClose: 800,
                position: 'top-center',
              })
              getUserData(planId);
            }
            else {
              toast.error("Something went wrong!!", {
                autoClose: 800,
                position: 'top-center',
              })
            }
          })
          .catch((error) => {
            toast.error("Something went wrong!!", {
              autoClose: 800,
              position: 'top-center',
            })
          })
      }
    })
  }

  const fetchPricingData = async (type, duration) => {
    const data = await getPricingData(pricingType, duration);
    setGetPrice(data);
  };


  return (

    <Wrapper id="pricing">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Check Our Pricing</h1>
            <p className="font13" style={{marginLeft:"9px"}}>
              Discover our competitive and flexible pricing plans designed to meet the needs of businesses of all sizes. Choose from our affordable options and unlock the full potential of our software, ensuring you get the best value for your investment. Whether you're just starting or scaling up, we have the perfect plan for you.
            </p>
            {loading1 && (
              <>
                <div className="spinner-overlay">
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    style={{ width: 50, height: 50 }}
                    variant="danger"
                    role="status"
                    aria-hidden="true"
                  />
                </div>

              </>
            )}

            <ButtonWrapper style={{ marginBottom: 10 }}>
              <StyledButton primary onClick={() => { setPricingType("EYMS"); getPricingData("EYMS", type); }}>EYMS Pricing</StyledButton>
              <StyledButton secondary onClick={() => { setPricingType("BWMS"); getPricingData("BWMS", type); }}>BWMS Pricing</StyledButton>
            </ButtonWrapper>
            <hr />
            {username && (
              <Row className="text-start">
                <Col md={4}>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <Label for="state"></Label>

                    <select
                      value={selectBranch}
                      onChange={handleSelectBranch}
                      className="form-control form-select"
                      name="state"
                      id="state"

                    >
                      <option value="">Select Branch</option>
                      {branch.map((item, index) => (
                        <option key={index} value={item.branchId}>{item.branchName}</option>
                      ))}

                    </select>

                  </FormGroup>
                </Col>
                <Col md={4}>
                </Col>
              </Row>
            )}

          </HeaderInfo>
          {pricingType === 'EYMS' ? (
            <div style={{textAlign:'center'}}>


              <div >
                <table style={{ borderCollapse: 'collapse', width: '100%', margin: 'auto', fontFamily: 'Arial, sans-serif', backgroundColor: '#f9f9f9', border: '1px solid #ddd' }}>
                  <thead>
                    <tr style={{ backgroundColor: 'red', color: 'white', textAlign: 'center' }}>
                      <th></th>
                      {Object.keys(planDtls).map((key, index) => (
                        <th key={index}>{key}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {plans.map((item, index) => (
                      <React.Fragment key={index}>
                        <tr>
                          <td style={{ textAlign: 'center', verticalAlign: 'middle', padding: '8px', border: '1px solid #ddd' }}>{item.planDtls}</td>
                          {Object.keys(planDtls).map((key, keyIndex) => (
                            <td style={{ textAlign: 'center', verticalAlign: 'middle', padding: '8px', border: '1px solid #ddd' }} key={keyIndex}>
                              {(() => {
                                const dtl = planDtls[key].find(dtl => dtl.planId === item.planId);
                                if (dtl) {
                                  if (dtl.status === 'N') {
                                    return <CloseMark />;
                                  } else if (dtl.status === 'Y') {
                                    return <Checkmark />;
                                  } else {
                                    return dtl.status;
                                  }
                                }
                                return '';
                              })()}
                            </td>
                          ))}
                        </tr>
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
              </div>


              <ButtonWrapper className="text-center" style={{ marginBottom: 10, borderRadius: 999 }}>
                <StyledButton style={{ borderRadius: 999 }} primary onClick={() => { setType("Month"); getPricingData(pricingType, "Month"); }}>Monthly</StyledButton>
                <StyledButton style={{ borderRadius: 999 }} primary onClick={() => { setType("3 Months"); getPricingData(pricingType, "3 Months"); }}>Quarterly</StyledButton>
                <StyledButton style={{ borderRadius: 999 }} primary onClick={() => { setType("Year"); getPricingData(pricingType, "Year"); }}>Yearly</StyledButton>
              </ButtonWrapper>

              <Row style={{ marginTop: 50 ,justifyContent: 'space-between'}}>
                {getPrice.map((item, index) => (

                  <Col md={3} className="priceModal" key={item.id} >
                    <FormGroup>
                      <div className="text-center priceHead">
                        {item.type} {item.packageName}
                      </div>
                      {item.discount !== '0' ? (
                        <>
                          <div className="text-center price">
                            Original Price <span style={{ textDecoration: 'line-through' }}>Rs. {item.originalPrice} / {item.duration}</span>
                          </div>
                          <div className="text-center price">
                            Rs. {item.price} / {item.duration} (You save {item.discount}%)
                          </div>
                        </>
                      )
                        :
                        (
                          <>
                            <div className="text-center price" style={{ marginTop: 10 }}>
                              Rs. {item.price} / {item.duration} (You save {item.discount}%)
                            </div>
                          </>
                        )
                      }

                      <br />
                      <div className="text-center">
                        <Button disabled={branchtate.eymsPlanId === item.id && new Date(branchtate.eymsEndDate) > new Date()} onClick={() => getPayment(item.price, item.id, item.type)} outline style={{ marginRight: 5 }} color="success">
                          <FontAwesomeIcon
                            icon={faMoneyBill} style={{ marginRight: 5 }}
                          />
                          Buy
                        </Button>
                        {(branchtate.eymsPlanId === item.id || branchtate.bwmsPlanId === item.id) && (
                          <Button outline onClick={() => handleOpenModal(item.id, item.user)} color="primary">
                            <FontAwesomeIcon
                              icon={faUser} style={{ marginRight: 5 }}

                            />
                            Add User
                          </Button>
                        )}

                      </div>
                    </FormGroup>
                  </Col>
                ))}

              </Row>

            </div>
          ) :
            (
              <div >


                <div>
                  <table style={{ borderCollapse: 'collapse', width: '100%', margin: 'auto', fontFamily: 'Arial, sans-serif', backgroundColor: '#f9f9f9', border: '1px solid #ddd' }}>
                    <thead>
                      <tr style={{ backgroundColor: 'red', color: 'white', textAlign: 'center' }}>
                        <th></th>
                        {Object.keys(planDtls).map((key, index) => (
                          <th key={index}>{key}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {plans.map((item, index) => (
                        <React.Fragment key={index}>
                          <tr>
                            <td style={{ textAlign: 'center', verticalAlign: 'middle', padding: '8px', border: '1px solid #ddd' }}>{item.planDtls}</td>
                            {Object.keys(planDtls).map((key, keyIndex) => (
                              <td style={{ textAlign: 'center', verticalAlign: 'middle', padding: '8px', border: '1px solid #ddd' }} key={keyIndex}>
                                {(() => {
                                  const dtl = planDtls[key].find(dtl => dtl.planId === item.planId);
                                  if (dtl) {
                                    if (dtl.status === 'N') {
                                      return <CloseMark />;
                                    } else if (dtl.status === 'Y') {
                                      return <Checkmark />;
                                    } else {
                                      return dtl.status;
                                    }
                                  }
                                  return '';
                                })()}
                              </td>
                            ))}
                          </tr>
                        </React.Fragment>
                      ))}
                    </tbody>
                  </table>
                </div>







                <ButtonWrapper className="text-center" style={{ marginBottom: 10 }}>
                  <StyledButton style={{ borderRadius: 999 }} primary onClick={() => { setType("Month"); getPricingData(pricingType, "Month"); }}>Monthly</StyledButton>
                  <StyledButton style={{ borderRadius: 999 }} primary onClick={() => { setType("3 Months"); getPricingData(pricingType, "3 Months"); }}>Quarterly</StyledButton>
                  <StyledButton style={{ borderRadius: 999 }} primary onClick={() => { setType("Year"); getPricingData(pricingType, "Year"); }}>Yearly</StyledButton>
                </ButtonWrapper>

                 <Row style={{ marginTop: 50 ,justifyContent: 'space-between'}}>
                  {getPrice.map((item, index) => (

                    <Col md={3}  className="priceModal" key={item.id} >
                      <FormGroup>
                        <div className="text-center priceHead">
                          {item.type} {item.packageName}
                        </div>
                        {item.discount !== '0' ? (
                          <>
                            <div className="text-center price">
                              Original Price <span style={{ textDecoration: 'line-through' }}>Rs. {item.originalPrice} / {item.duration}</span>
                            </div>
                            <div className="text-center price">
                              Rs. {item.price} / {item.duration} (You save {item.discount}%)
                            </div>
                          </>
                        )
                          :
                          (
                            <>

                              <div className="text-center price" style={{ marginTop: 10 }}>
                                Rs. {item.price} / {item.duration} (You save {item.discount}%)
                              </div>
                            </>
                          )
                        }


                        <br />
                        <div className="text-center">
                          <Button disabled={branchtate.bwmsPlanId === item.id && new Date(branchtate.bwmsEndDate) > new Date()} onClick={() => getPayment(item.price, item.id, item.type)} outline style={{ marginRight: 5 }} color="success">
                            <FontAwesomeIcon
                              icon={faMoneyBill} style={{ marginRight: 5 }}
                            />
                            Buy
                          </Button>
                          {(branchtate.eymsPlanId === item.id || branchtate.bwmsPlanId === item.id) && (
                            <Button outline onClick={() => handleOpenModal(item.id, item.user)} color="primary">
                              <FontAwesomeIcon
                                icon={faUser} style={{ marginRight: 5 }}

                              />
                              Add User
                            </Button>
                          )}

                        </div>
                      </FormGroup>
                    </Col>
                  ))}

                </Row> 
              </div>
            )}
        </div>

        <Modal Modal isOpen={isModalOpenForAddUser} onClose={closeModal} toggle={closeModal} style={{ maxWidth: '800px', wioverflow: '-moz-hidden-unscrollable' }}>

          <ModalHeader toggle={closeModal} style={{
            backgroundColor: 'red', color: 'black', fontFamily: 'Your-Heading-Font', textAlign: 'center', background: 'red',
            boxShadow: '0px 5px 10px rgba(0, 77, 64, 0.3)',
            border: '1px solid rgba(0, 0, 0, 0.3)',
            borderRadius: '0',
            backgroundColor: 'red', backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat', backgroundPosition: 'center',
          }}   >
            <h5 className="pageHead" style={{ fontFamily: 'Your-Heading-Font', color: 'white', }} > <FontAwesomeIcon
              icon={faPlus}
              style={{
                marginRight: '8px',
                color: 'white', // Set the color to golden
              }}
            />Add User</h5>
            {loading && (
              <>
                <div className="spinner-overlay">
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    style={{ width: 50, height: 50 }}
                    variant="danger"
                    role="status"
                    aria-hidden="true"
                  />
                </div>

              </>
            )}

          </ModalHeader>
          <ModalBody style={{ backgroundImage: 'url(https://img.freepik.com/free-vector/gradient-wavy-background_23-2149123392.jpg?t=st=1694859409~exp=1694860009~hmac=b397945a9c2d45405ac64956165f76bd10a0eff99334c52cd4c88d4162aad58e)', backgroundSize: 'cover', fontSize: 12 }}>
            <Row>
              <Col md={4}>
                <FormGroup>
                  <Label for="userId">User ID</Label> <span style={{ color: 'red' }}>*</span>
                  <Input
                    type="text"
                    name="userId"
                    id="userId"
                    value={formState.userId}
                    onChange={handleChange}
                    maxLength={50}
                    style={{ height: '33px' }}
                  />
                  <div style={{ color: 'red' }} className="error-message">{formErrors.userId}</div>
                </FormGroup>
              </Col>

              <Col md={4}>
                <FormGroup>
                  <Label for="username">Username</Label> <span style={{ color: 'red' }}>*</span>
                  <Input
                    type="text"
                    name="username"
                    id="username"
                    value={formState.username}
                    onChange={handleChange}
                    maxLength={100}
                    style={{ height: '33px' }}
                  />
                  <div style={{ color: 'red' }} className="error-message">{formErrors.username}</div>
                </FormGroup>
              </Col>

              <Col md={4}>
                <FormGroup>
                  <Label for="password">Password</Label> <span style={{ color: 'red' }}>*</span>
                  <Input
                    type="password"
                    name="password"
                    id="password"
                    value={formState.password}
                    onChange={handleChange}
                    maxLength={20}
                    style={{ height: '33px' }}
                  />
                  <div style={{ color: 'red' }} className="error-message">{formErrors.password}</div>
                </FormGroup>
              </Col>


            </Row>
            <Row>
              <Col md={4}>
                <FormGroup>
                  <Label for="email">Email</Label> <span style={{ color: 'red' }}>*</span>
                  <Input
                    type="email"
                    name="email"
                    id="email"
                    value={formState.email}
                    onChange={handleChange}
                    maxLength={50}
                    style={{ height: '33px' }}
                  />
                  <div style={{ color: 'red' }} className="error-message">{formErrors.email}</div>
                </FormGroup>
              </Col>

              <Col md={4}>
                <FormGroup>
                  <Label for="mobile">Mobile</Label> <span style={{ color: 'red' }}>*</span>
                  <Input
                    type="text"
                    name="mobile"
                    id="mobile"
                    value={formState.mobile}
                    onChange={handleChange}
                    maxLength={15}
                    style={{ height: '33px' }}
                  />
                  <div style={{ color: 'red' }} className="error-message">{formErrors.mobile}</div>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col className="text-center">
                <button
                  type="button"
                  className="btn  btn-danger"
                  id="submitbtn"
                  onClick={handleSave}
                  disabled={(flag === 'add' && user == userData.length)}
                >

                  Save
                </button>
                <button
                  type="button"
                  className="btn  btn-danger"
                  style={{ marginLeft: 10 }}
                  onClick={handleClear}
                >

                  Clear
                </button>
              </Col>
            </Row>
            <div style={{ marginTop: 10 }} className="table-responsive">
              <Table className="table table-bordered custom-table">
                <thead style={{ backgroundColor: 'rgb(226 232 240)' }}>
                  <tr className="text-center" >
                    <th style={{ backgroundColor: 'white' }} scope="col" >#</th>
                    <th style={{ backgroundColor: 'white' }} scope="col">User Id</th>
                    <th style={{ backgroundColor: 'white' }} scope="col">Username</th>
                    <th style={{ backgroundColor: 'white' }} scope="col">Email</th>
                    <th style={{ backgroundColor: 'white' }} scope="col">Mobile</th>
                    <th style={{ backgroundColor: 'white' }} scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {userData.map((item, index) => (
                    <tr key={index} className='text-center'>
                      <td>{index + 1}</td>
                      <td>{item.userId}</td>
                      <td>{item.username}</td>
                      <td>{item.email}</td>
                      <td>{item.mobile}</td>
                      <td>
                        <button
                          type="button"
                          className="btn  btn-danger"
                          onClick={() => handleEdit(item.autoId)}
                          id="submitbtn"
                          style={{ marginRight: "5px" }}
                        >
                          <FontAwesomeIcon
                            icon={faEdit}
                          />

                        </button>
                        <button
                          type="button"
                          className="btn  btn-danger"
                          onClick={() => handleDelete(item.autoId)}
                          id="submitbtn"
                        >
                          <FontAwesomeIcon
                            icon={faTrash}
                          />

                        </button>


                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </ModalBody>
        </Modal>
      </div>

    </Wrapper>

  );
}
const PricingTablesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
`;


const Wrapper = styled.section`
  width: 100%;
  padding: 50px 0;
  .fade-enter {
    opacity: 0;
    transform: translateY(-20px);
  }
  .fade-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 500ms, transform 500ms;
  }
  .fade-exit {
    opacity: 1;
    transform: translateY(0);
  }
  .fade-exit-active {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 500ms, transform 500ms;
  }
`;



const HeaderInfo = styled.div`
  margin-bottom: 50px;
  text-align: center;
`;
const ButtonWrapper = styled.div`
  margin-top: 20px;
`;
const StyledButton = styled.button`
  background-color: ${(props) => (props.primary ? "#0070f3" : "#f37100")};
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 0 10px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
  transition: background-color 0.3s, transform 0.3s;

  &:hover {
    background-color: ${(props) => (props.primary ? "#005bb5" : "#d95a00")};
    transform: scale(1.05);
  }
`;
const TableBox = styled.div`
  width: 31%;
  margin: 0 1%;

  @media (max-width: 860px) {
    width: 100%;
    max-width: 370px;
    margin: 0 auto 20px;
  }
`;
