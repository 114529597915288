import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { Link as ScrollLink } from "react-scroll";
import { Navigate, useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faFaceGrinStars, faHistory, faPlus, faSave, faSignOutAlt, faSyncAlt, faTrash } from "@fortawesome/free-solid-svg-icons";
import AuthContext from "../AuthProvider";
import RapportSoftlogo from "../../assets/img/image copy.png";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import DatePicker from "react-datepicker";
import Swal from 'sweetalert2';
import ReactLoading from 'react-loading';
import "../Style1.css";
import BurgerIcon from "../../assets/svg/BurgerIcon";
import ipaddress from "../ipaddress"
import useAxios from "../Sections/useAxios"
import Sidebar from "../Nav/Sidebar";
import Backdrop from "../Elements/Backdrop";
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Table,
} from "reactstrap";
import Spinner from 'react-bootstrap/Spinner';
import { toast } from "react-toastify";
// import Sidebar from "../Nav/Sidebar";
// import Backdrop from "../Elements/Backdrop";

export default function TopNavbar() {
  const [y, setY] = useState(window.scrollY);
  const [sidebarOpen, toggleSidebar] = useState(false);
  const axios = useAxios();
  const {
    username,
    logout,
    companyid,
    jwtToken,
    userId
  } = useContext(AuthContext) || {};

  const [states, setStates] = useState([]);
  const [city, setCity] = useState([]);


  const getStates = () => {
    axios.get(`${ipaddress}jar/getData?jarid=ST`)
      .then((response) => {
        setStates(response.data);
      })
      .catch((error) => {

      })
  }

  const getCity = () => {
    axios.get(`${ipaddress}jar/getData?jarid=CTY`)
      .then((response) => {
        setCity(response.data);
      })
      .catch((error) => {

      })
  }



  const navigate = useNavigate();

  // useEffect(() => {
  //   const handleScroll = () => setY(window.scrollY);
  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, [y]);

  useEffect(() => {
    window.addEventListener("scroll", () => setY(window.scrollY));
    return () => {
      window.removeEventListener("scroll", () => setY(window.scrollY));
    };
  }, [y]);

  const handleLogout = () => {
    logout();
    navigate("/logIn");
  };

  const [isOpenAddBranchModal, setIsOpenAddBranchModal] = useState(false);

  const handleOpenBranch = () => {
    setIsOpenAddBranchModal(true);
    allBranches();
    getStates();
    getCity();
    navigate('/', { state: { status: 'add' } })
  }

  const closeBranch = () => {
    navigate('/', { state: { status: 'close' } })
    setIsOpenAddBranchModal(false);
    setFlag('add');
    handleClear();

  }

  const [branches, setBranches] = useState([]);
  const allBranches = () => {
    axios.get(`${ipaddress}branch/getBranch?cid=${companyid}`, {
      headers: {
        'Authorization': `Bearer ${jwtToken}` // Replace authToken with your actual token variable
      }
    })
      .then((response) => {
        setBranches(response.data);
        console.log(response.data);
      })
      .catch((error) => {

      })
  }

  const [formErrors, setFormErrors] = useState({
    branchName: "",
    address: "",
    city: "",
    state: "",
    pin: "",
    GST_No: "",
    Pan_No: "",
    Contact_Person: "",
    Designation: "",
    Contact_Phone_No: "",
    Contact_Email: "",
  })

  const [formState, setFormState] = useState({
    companyId: "",
    branchId: "",
    branchName: "",
    status: "",
    address: "",
    gst_No: "",
    pan_No: "",
    city: "",
    state: "",
    country: "",
    pin: "",
    contact_Person: "",
    designation: "",
    contact_Phone_No: "",
    contact_Email: "",
    tanNo: "",
    comments: "",
    createdBy: "",
    createdDate: null,
    editedBy: "",
    editedDate: null,
    approvedBy: "",
    approvedDate: null,
    transactionId: "",
    transactionStatus: "",
    eymsPlanId: "",
    bwmsPlanId: "",
    eymsStartDate: null,
    eymsEndDate: null,
    bwmsStartDate: null,
    bwmsEndDate: null
  });

  // Function to handle changes in the form
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'contact_Phone_No' || name === 'pin') {
      setFormState({
        ...formState,
        [name]: handleInputChange(value)
      });
    }
    else {
      setFormState({
        ...formState,
        [name]: value
      });
    }
  };

  const [flag, setFlag] = useState('flag');

  const validateGST = (gstNo) => /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/.test(gstNo);
  const validatePAN = (panNo) => /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(panNo);
  const validateMobile = (mobileNo) => /^[6-9][0-9]{9}$/.test(mobileNo);
  const validateEmail = (email) => /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email);
  const [loading, setLoading] = useState(false);
  const saveData = () => {
    setLoading(true);
    setFormErrors({
      branchName: "",
      address: "",
      city: "",
      state: "",
      pin: "",
      GST_No: "",
      Pan_No: "",
      Contact_Person: "",
      Designation: "",
      Contact_Phone_No: "",
      Contact_Email: "",
    })
    document.getElementById('branchName').classList.remove('error-border');
    document.getElementById('address').classList.remove('error-border');
    document.getElementById('city').classList.remove('error-border');
    document.getElementById('state').classList.remove('error-border');
    document.getElementById('pin').classList.remove('error-border');
    document.getElementById('gst_No').classList.remove('error-border');
    document.getElementById('pan_No').classList.remove('error-border');
    document.getElementById('contact_Person').classList.remove('error-border');
    document.getElementById('designation').classList.remove('error-border');
    document.getElementById('contact_Phone_No').classList.remove('error-border');
    document.getElementById('contact_Email').classList.remove('error-border');
    const submitBtn = document.getElementById("submitbtn");
    submitBtn.disabled = true;
    let errors = {};

    if (!formState.branchName) {
      errors.branchName = "Branch name is required.";
      document.getElementById('branchName').classList.add('error-border');
    }

    if (!formState.address) {
      errors.address = "Address is required.";
      document.getElementById('address').classList.add('error-border');
    }

    if (!formState.city) {
      errors.city = "City is required.";
      document.getElementById('city').classList.add('error-border');
    }

    if (!formState.state) {
      errors.state = "State is required.";
      document.getElementById('state').classList.add('error-border');
    }

    if (!formState.pin) {
      errors.pin = "Pin code is required.";
      document.getElementById('pin').classList.add('error-border');
    }

    if (!formState.gst_No) {
      errors.GST_No = "GST No is required.";
      document.getElementById('gst_No').classList.add('error-border');
    }
    else {
      if (!validateGST(formState.gst_No)) {
        errors.GST_No = "Invalid GST No.";
        document.getElementById('gst_No').classList.add('error-border');
      }
    }

    if (!formState.pan_No) {
      errors.Pan_No = "PAN no is required.";
      document.getElementById('pan_No').classList.add('error-border');
    }
    else {
      if (!validatePAN(formState.pan_No)) {
        errors.Pan_No = "Invalid PAN No.";
        document.getElementById('pan_No').classList.add('error-border');
      }
    }

    if (!formState.contact_Person) {
      errors.Contact_Person = "Contact Person is required.";
      document.getElementById('contact_Person').classList.add('error-border');
    }

    if (!formState.designation) {
      errors.Designation = "Designation is required.";
      document.getElementById('designation').classList.add('error-border');
    }

    if (!formState.contact_Phone_No) {
      errors.Contact_Phone_No = "Contact phone no is required.";
      document.getElementById('contact_Phone_No').classList.add('error-border');
    }
    else {
      if (!validateMobile(formState.contact_Phone_No)) {
        errors.Contact_Phone_No = "Invalid Contact No.";
        document.getElementById('contact_Phone_No').classList.add('error-border');
      }
    }

    if (!formState.contact_Email) {
      errors.Contact_Email = "Contact email is required.";
      document.getElementById('contact_Email').classList.add('error-border');
    }
    else {
      if (!validateEmail(formState.contact_Email)) {
        errors.Contact_Email = "Invalid email.";
        document.getElementById('contact_Email').classList.add('error-border');
      }
    }

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      submitBtn.disabled = false;
      setLoading(false);
      return;
    }

    axios.post(`${ipaddress}branch/saveBranch?cid=${companyid}&user=${userId}&flag=${flag}`, formState, {
      headers: {
        'Authorization': `Bearer ${jwtToken}`
      }
    })
      .then((response) => {
        toast.success("Data save successfully!!", {
          autoClose: 800
        })
        setLoading(false);
        handleClear();
        submitBtn.disabled = false;
        allBranches();

      })
      .catch((error) => {
        toast.error(error.response.data, {
          autoClose: 800
        })
        submitBtn.disabled = false;
        setLoading(false);
      })

  }

  const handleClear = () => {
    setFormState({
      companyId: "",
      branchId: "",
      branchName: "",
      status: "",
      address: "",
      gst_No: "",
      pan_No: "",
      city: "",
      state: "",
      country: "",
      pin: "",
      contact_Person: "",
      designation: "",
      contact_Phone_No: "",
      contact_Email: "",
      tanNo: "",
      comments: "",
      createdBy: "",
      createdDate: null,
      editedBy: "",
      editedDate: null,
      approvedBy: "",
      approvedDate: null,
      transactionId: "",
      transactionStatus: "",
      eymsPlanId: "",
      bwmsPlanId: "",
      eymsStartDate: null,
      eymsEndDate: null,
      bwmsStartDate: null,
      bwmsEndDate: null
    });
    setFormErrors({
      branchName: "",
      address: "",
      city: "",
      state: "",
      pin: "",
      GST_No: "",
      Pan_No: "",
      Contact_Person: "",
      Designation: "",
      Contact_Phone_No: "",
      Contact_Email: "",
    })
    document.getElementById('branchName').classList.remove('error-border');
    document.getElementById('address').classList.remove('error-border');
    document.getElementById('city').classList.remove('error-border');
    document.getElementById('state').classList.remove('error-border');
    document.getElementById('pin').classList.remove('error-border');
    document.getElementById('gst_No').classList.remove('error-border');
    document.getElementById('pan_No').classList.remove('error-border');
    document.getElementById('contact_Person').classList.remove('error-border');
    document.getElementById('designation').classList.remove('error-border');
    document.getElementById('contact_Phone_No').classList.remove('error-border');
    document.getElementById('contact_Email').classList.remove('error-border');
    setFlag('add');

  }

  function handleInputChange(e) {
    const inputValue = e;
    const numericInput = inputValue.replace(/[^0-9.]/g, '');
    const parts = numericInput.split('.');
    const integerPart = parts[0];
    let decimalPart = parts[1];

    // Limit decimal places if needed
    if (decimalPart !== undefined) {
      decimalPart = `.${decimalPart.slice(0, 2)}`;
    }

    const sanitizedInput = decimalPart !== undefined ? `${integerPart}${decimalPart}` : integerPart;
    return sanitizedInput;
  };


  const handleEdit = (bid) => {
    axios.get(`${ipaddress}branch/getBranchById?cid=${companyid}&bid=${bid}`, {
      headers: {
        'Authorization': `Bearer ${jwtToken}`
      }
    })
      .then((response) => {
        setFormState(response.data);
        setFlag('edit');
      })
      .catch((error) => {

      })
  }

  const handleDelete = (bid) => {
    Swal.fire({
      title: 'Are you sure?',
      html: `Are you sure you want to delete the record?`,
      icon: 'warning',
      background: '#ffffff',
      showCancelButton: true,
      customClass: {
        icon: 'icon-smaller', // Apply the custom class to the icon
        popup: 'custom-swal-popup',
        title: 'custom-swal-title',
      },
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, close it!'
    }).then((result) => {
      if (result.isConfirmed) {
        axios.post(`${ipaddress}branch/deleteBranch?cid=${companyid}&bid=${bid}&user=${userId}`, null, {
          headers: {
            'Authorization': `Bearer ${jwtToken}`
          }
        })
          .then((response) => {
            if (response.data === 'success') {
              toast.error("Data deleted successfully!!", {
                autoClose: 800
              })
              allBranches();
            }
            else {
              toast.error("Branch not found!!", {
                autoClose: 800
              })
            }
          })
          .catch((error) => {

          })
      }
    })
  }

  const handleSubmit = () => {
    setLoading(true);
    // Simulate a network request
    setTimeout(() => {
      setLoading(false);

    }, 2000);
  };


  return (
    <>

      <Sidebar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
      {sidebarOpen &&
        <Backdrop toggleSidebar={toggleSidebar} />}

      <Wrapper className="flexCenter animate whiteBg" style={y > 100 ? { height: "60px" } : { height: "80px" }}
        grid={
          {
            gutter: 16,
            xs: 1,
            sm: 2,
            md: 4,
            lg: 4, xl: 6, xxl: 3,
          }
        }>
        <NavInner className="container flexSpaceCenter">
          <ScrollLink className="pointer flexNullCenter" to="home" smooth={true}>
            <img
              alt="..."
              src={RapportSoftlogo}
              style={{ width: "180px", height: "auto" }} // Adjust the width and height as needed
            />
          </ScrollLink>
          <BurderWrapper className="pointer" onClick={() => toggleSidebar(!sidebarOpen)}>
            <BurgerIcon />
          </BurderWrapper>
          <UlWrapper className="flexNullCenter">
            {["home", "services", "projects", "about", "pricing"].map((section) => (
              <li key={section} className="semiBold font15 pointer">
                <ScrollLink
                  activeClass="active"
                  style={{ padding: "10px 15px" }}
                  to={section}
                  spy={true}
                  smooth={true}
                  offset={-80}
                >
                  {section.charAt(0).toUpperCase() + section.slice(1)}
                </ScrollLink>
              </li>
            ))}
            {username && (
              <li className="semiBold font15 pointer">
                <ScrollLink
                  activeClass="active"
                  style={{ padding: "10px 15px" }}
                  onClick={handleOpenBranch}

                >
                  Add Branch
                </ScrollLink>
              </li>

            )}

          </UlWrapper>

          
          <UlWrapperRight className="flexNullCenter">
            {username ? (
              <>
                <li className="semiBold font15 pointer">
                  <StyledButton>
                    <FontAwesomeIcon icon={faFaceGrinStars} className="icon" />
                    {username}
                  </StyledButton>
                </li>
                <li className="semiBold font15 pointer">
                  <StyledButton onClick={handleLogout}>
                    <FontAwesomeIcon icon={faSignOutAlt} className="icon" />
                    Logout
                  </StyledButton>
                </li>
              </>
            ) : (
              <>
                <li className="semiBold font15 pointer">
                  <Link to="/logIn" style={{ padding: "10px 30px 10px 0" }}>
                    Log in
                  </Link>
                </li>
                <li className="semiBold font15 pointer flexCenter">
                  <ScrollLink activeClass="active" style={{ padding: "10px 15px" }} to="contact" spy={true} smooth={true} offset={-80}>
                  <Link to="/register" style={{ padding: "10px 30px 10px 0" }}>
                    Register   </Link>
                  </ScrollLink>
                </li>
              </>
            )}
          </UlWrapperRight>
        </NavInner>
      </Wrapper>

      <Modal Modal isOpen={isOpenAddBranchModal} onClose={closeBranch} toggle={closeBranch} style={{ maxWidth: '800px', wioverflow: '-moz-hidden-unscrollable' }}>

        <ModalHeader toggle={closeBranch} style={{
          backgroundColor: 'red', color: 'black', fontFamily: 'Your-Heading-Font', textAlign: 'center', background: 'red',
          boxShadow: '0px 5px 10px rgba(0, 77, 64, 0.3)',
          border: '1px solid rgba(0, 0, 0, 0.3)',
          borderRadius: '0',
          backgroundColor: 'red',
          // backgroundColor: 'rgba(0, 0, 0, 0.3)',
          // backgroundImage: 'url("https://images.pexels.com/photos/1103970/pexels-photo-1103970.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1")',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          //backgroundPosition: 'center',
          backgroundPosition: 'center',
        }}   >
          <h5 className="pageHead" style={{ fontFamily: 'Your-Heading-Font', color: 'white', }} > <FontAwesomeIcon
            icon={faPlus}
            style={{
              marginRight: '8px',
              color: 'white', // Set the color to golden
            }}
          />Add Branch</h5>
          {loading && (
            <>
              <div className="spinner-overlay">
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  style={{ width: 50, height: 50 }}
                  variant="danger"
                  role="status"
                  aria-hidden="true"
                />
              </div>

            </>
          )}
        </ModalHeader>
        <hr />
        <ModalBody style={{ backgroundImage: 'url(https://img.freepik.com/free-vector/gradient-wavy-background_23-2149123392.jpg?t=st=1694859409~exp=1694860009~hmac=b397945a9c2d45405ac64956165f76bd10a0eff99334c52cd4c88d4162aad58e)', backgroundSize: 'cover', fontSize: 12 }}>
          <Row>
            <Col md={3}>
              <FormGroup>
                <Label for="branchName">Branch name</Label> <span style={{ color: 'red' }}>*</span>
                <Input
                  type="text"
                  name="branchName"
                  id="branchName"
                  value={formState.branchName}
                  onChange={handleChange}
                  maxLength={35}
                  style={{ height: '33px' }}
                />
                <div style={{ color: 'red' }} className="error-message">{formErrors.branchName}</div>
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <Label for="address">Address</Label><span style={{ color: 'red' }}>*</span>
                <Input
                  type="text"
                  name="address"
                  id="address"
                  value={formState.address}
                  maxLength={255}
                  onChange={handleChange}
                  style={{ height: '33px' }}
                />
                <div style={{ color: 'red' }} className="error-message">{formErrors.address}</div>
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <Label for="state">State</Label><span style={{ color: 'red' }}>*</span>
                {/* <Input
                  type="text"
                  name="state"
                  id="state"
                  value={formState.state}
                  onChange={handleChange}
                  maxLength={35}
                  style={{ height: '33px' }}
                /> */}
                <select

                  className="form-control form-select"
                  name="state"
                  id="state"
                  value={formState.state}
                  onChange={handleChange}

                >
                  <option value="">Select State</option>
                  {states.map((item, index) => (
                    <option key={index} value={item.jarDtlId}>{item.jarDtlDesc}</option>
                  ))

                  }
                </select>
                <div style={{ color: 'red' }} className="error-message">{formErrors.state}</div>
              </FormGroup>
            </Col>
            <Col md={3}>

              <FormGroup>
                <Label for="city">City</Label><span style={{ color: 'red' }}>*</span>
                {/* <Input
                  type="text"
                  name="city"
                  id="city"
                  value={formState.city}
                  onChange={handleChange}
                  maxLength={35}
                  style={{ height: '33px' }}
                /> */}
                <select

                  className="form-control form-select"
                  name="city"
                  id="city"
                  value={formState.city}
                  onChange={handleChange}

                >
                  <option value="">Select City</option>
                  {city.map((item, index) => (
                    <option key={index} value={item.jarDtlId}>{item.jarDtlDesc}</option>
                  ))

                  }
                </select>

                <div style={{ color: 'red' }} className="error-message">{formErrors.city}</div>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <FormGroup>
                <Label for="pin">Pin Code</Label><span style={{ color: 'red' }}>*</span>
                <Input
                  type="text"
                  name="pin"
                  id="pin"
                  value={formState.pin}
                  maxLength={6}
                  onChange={handleChange}
                  style={{ height: '33px' }}
                />
                <div style={{ color: 'red' }} className="error-message">{formErrors.pin}</div>
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <Label for="Pan_No">PAN No</Label><span style={{ color: 'red' }}>*</span>
                <Input
                  type="text"
                  name="pan_No"
                  id="pan_No"
                  value={formState.pan_No}
                  maxLength={15}
                  onChange={handleChange}
                  style={{ height: '33px' }}
                />
                <div style={{ color: 'red' }} className="error-message">{formErrors.Pan_No}</div>
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <Label for="GST_No">GST No</Label><span style={{ color: 'red' }}>*</span>
                <Input
                  type="text"
                  name="gst_No"
                  id="gst_No"
                  value={formState.gst_No}
                  maxLength={30}
                  onChange={handleChange}
                  style={{ height: '33px' }}
                />
                <div style={{ color: 'red' }} className="error-message">{formErrors.GST_No}</div>
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <Label for="tanNo">TAN No</Label>
                <Input
                  type="text"
                  name="tanNo"
                  id="tanNo"
                  maxLength={25}
                  value={formState.tanNo}
                  onChange={handleChange}
                  style={{ height: '33px' }}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <FormGroup>
                <Label for="Contact_Person">Person Name</Label><span style={{ color: 'red' }}>*</span>
                <Input
                  type="text"
                  name="contact_Person"
                  id="contact_Person"
                  value={formState.contact_Person}
                  maxLength={35}
                  onChange={handleChange}
                  style={{ height: '33px' }}
                />
                <div style={{ color: 'red' }} className="error-message">{formErrors.Contact_Person}</div>
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <Label for="Designation">Person Designation</Label><span style={{ color: 'red' }}>*</span>
                <Input
                  type="text"
                  name="designation"
                  id="designation"
                  value={formState.designation}
                  maxLength={35}
                  onChange={handleChange}
                  style={{ height: '33px' }}
                />
                <div style={{ color: 'red' }} className="error-message">{formErrors.Designation}</div>
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <Label for="Contact_Phone_No">Contact No</Label><span style={{ color: 'red' }}>*</span>
                <Input
                  type="text"
                  name="contact_Phone_No"
                  id="contact_Phone_No"
                  value={formState.contact_Phone_No}
                  maxLength={15}
                  onChange={handleChange}
                  style={{ height: '33px' }}
                />
                <div style={{ color: 'red' }} className="error-message">{formErrors.Contact_Phone_No}</div>
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <Label for="Contact_Email">Email</Label><span style={{ color: 'red' }}>*</span>
                <Input
                  type="text"
                  name="contact_Email"
                  id="contact_Email"
                  value={formState.contact_Email}
                  onChange={handleChange}
                  maxLength={50}
                  style={{ height: '33px' }}
                />
                <div style={{ color: 'red' }} className="error-message">{formErrors.Contact_Email}</div>
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col className="text-center">
              <button
                type="button"
                className="btn  btn-danger"
                onClick={saveData}
                id="submitbtn"
              >

                Save
              </button>
              <button
                type="button"
                className="btn  btn-danger"
                style={{ marginLeft: 10 }}
                onClick={handleClear}
              >

                Clear
              </button>
            </Col>
          </Row>
          <div style={{ marginTop: 10 }} className="table-responsive">
            <Table className="table table-bordered custom-table">
              <thead style={{ backgroundColor: 'rgb(226 232 240)' }}>
                <tr className="text-center" >
                  <th style={{ backgroundColor: 'white' }} scope="col" >#</th>
                  <th style={{ backgroundColor: 'white' }} scope="col">Branch Name</th>
                  <th style={{ backgroundColor: 'white' }} scope="col">Address</th>
                  <th style={{ backgroundColor: 'white' }} scope="col">GST No</th>
                  <th style={{ backgroundColor: 'white' }} scope="col">Email Id</th>
                  <th style={{ backgroundColor: 'white' }} scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {branches.map((item, index) => (
                  <tr key={index} className="text-center">
                    <td>{index + 1}</td>
                    <td>{item.branchName}</td>
                    <td>{item.address}</td>
                    <td>{item.gst_No}</td>
                    <td>{item.contact_Email}</td>
                    <td>
                      <button
                        type="button"
                        className="btn  btn-danger"
                        onClick={() => handleEdit(item.branchId)}
                        id="submitbtn"
                        style={{ marginRight: "5px" }}
                      >
                        <FontAwesomeIcon
                          icon={faEdit}
                        />

                      </button>
                      <button
                        type="button"
                        className="btn  btn-danger"
                        onClick={() => handleDelete(item.branchId)}
                        id="submitbtn"
                      >
                        <FontAwesomeIcon
                          icon={faTrash}
                        />

                      </button>

                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>

        </ModalBody>
      </Modal>

    </>
  );
}

const Wrapper = styled.nav`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
    @media (max-width: 760px) {
    display: block;
 }
`;
const NavInner = styled.div`
  position: relative;
  height: 100%;

`;
const BurderWrapper = styled.button`
  outline: none;
  border: 0px;
  background-color: transparent;
  height: 100%;
  padding: 0 15px;
  display: none;
  @media (max-width: 760px) {
     display: block;
  }
`;
const UlWrapper = styled.ul`
  display: flex;
  @media (max-width: 760px) {
    display: none;
  }
`;
const UlWrapperRight = styled.ul`
  @media (max-width: 760px) {
    display: none;
  }
`;

const StyledButton = styled.button`
  background-color: transparent;
  color: ${(props) => (props.primary ? "#0070f3" : "#000000")}; /* Change color to black */
  border: none;
  padding: 5px 5px;
  margin: 0 10px;
  cursor: pointer;
  font-size: 17px;
  border-radius: 5px;
  transition: color 0.3s, transform 0.3s;

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: ${(props) => (props.primary ? "#005bb5" : "#d95a00")};
    transform: scale(1.05);
  }

  & > .icon {
    margin-right: 8px;
  }
`;