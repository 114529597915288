
import React, { useState } from "react";
import styled from "styled-components";
import ProjectBox from "../Elements/ProjectBox";
import FullButton from "../Buttons/FullButton";
import ProjectImg1 from "../../assets/img/projects/eyms.jpeg";
import ProjectImg2 from "../../assets/img/projects/warehousing.jpeg";
import ProjectImg3 from "../../assets/img/projects/3.png";
import ProjectImg4 from "../../assets/img/projects/4.png";
import ProjectImg5 from "../../assets/img/projects/5.png";
import ProjectImg6 from "../../assets/img/projects/6.png";
import gatein1 from "../../Images/gatein.jpg"
import gatein from "../../assets/img/Gate In .avif";
import survey from "../../assets/img/survey.png";
import delevery from "../../assets/img/delevery .jpeg";
import gateout from "../../assets/img/gateout.avif";
import estimation from "../../assets/img/estimation.jpeg";
import tarrif from "../../assets/img/tarrif.jpeg";
import handling from "../../assets/img/handling.avif";
import noc from "../../Images/noc1.png"
import inbond from "../../Images/inbond.jpg"
import exbond from "../../Images/gateout.jpg"
import gatepass from "../../Images/gatepass.png"
import audit from "../../Images/audit.jpg"

export default function Projects() {
  const [showModal, setShowModal] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const [slideIndex, setSlideIndex] = useState(0);

  const projects = [
    {
      img: ProjectImg1,
    title: "Empty Yard Management System",
    text:
      "EYMS Software represents quality, reliability, and affordability in Software solutions for Empty Yard Management System (EYMS) and Warehouse Management.",
    slides: [
      {
        title: "Container Gate In",
        img: gatein,
        text:
          "Container Gate In is the process of receiving and documenting containers or shipments at a facility, ensuring compliance with safety and inventory protocols upon arrival."
      },
      {
        title: "Gate In Survey",
        img: survey,
        text:
          "The Container Gate In Survey is a quick inspection of incoming containers to assess damage, repairs needed, or replacements required, ensuring they meet safety and quality standards upon arrival for efficient operations."
      },
      {
        title: "Container Estimation",
        img: estimation,
        text:
          "Container Estimation is the process of evaluating damage in containers and providing cost estimates for required repairs or replacements. This helps in planning and budgeting for maintenance to ensure containers remain in optimal condition for operations."
      },
      {
        title: "Delivery Order",
        img: delevery,
        text:
          "The Delivery Order Process includes verifying, preparing, and coordinating the shipment of goods to customers or destinations, ensuring timely and accurate delivery."
      },
      {
        title: "Container Gate Out",
        img: gateout,
        text:
          "Container Gate Out involves preparing and releasing containers or shipments from a facility, coordinating with transport providers for efficient dispatch."
      },
      {
        title: "Storage & Handling",
        img: handling,
        text: "Storage & Handling involves the safe and efficient storage and movement of goods within the yard."
      },
      {
        title: "Party Wise Tariff",
        img: tarrif,
        text: "Party Wise Tariff is a feature that calculates and applies tariffs based on party or customer-specific rates."
      }
    ],
    },
    {
      img: ProjectImg2,
      title: "BondedWare House",
      text:
        "Our bonded warehouse software simplifies management of bonded goods with automated compliance, precise inventory control, and seamless integration. Enhance productivity and streamline operations with ease.",
      slides: [
        { title: "NOC", img: noc, text: "An official document authorizing the entry or exit of goods from a bonded warehouse." },
        { title: "Gate In", img: gatein1, text: "The process of recording and authorizing the entry of goods into the bonded warehouse." },
        { title: "Inbond", img: inbond, text: "The status of goods stored in a bonded warehouse under customs control, awaiting duty and tax payment." },
        { title: "Exbond", img: exbond, text: "The process of removing goods from a bonded warehouse after paying applicable duties and taxes." },
        { title: "Gate Pass", img: gatepass, text: "A document permitting the physical movement of goods in or out of the bonded warehouse, ensuring proper authorization and tracking." },
        { title: "Audit Trail", img: audit, text: "A comprehensive log maintained by BWMS tracking all user activities and system transactions for accountability and compliance." },
      ],
    },
    // {
    //   img: ProjectImg3,
    //   title: "Awesome Project 1",
    //   text:
    //     "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor.",
    //   slides: [
    //     { title: "Slide 1 for Awesome Project 1", img: ProjectImg3, text: "Slide 1 text for Awesome Project 1" },
    //     { title: "Slide 2 for Awesome Project 1", img: ProjectImg4, text: "Slide 2 text for Awesome Project 1" },
    //     { title: "Slide 3 for Awesome Project 1", img: ProjectImg5, text: "Slide 3 text for Awesome Project 1" },
    //   ],
    // },
  ];

  const handleProjectClick = (project) => {
    setSelectedProject(project);
    setShowModal(true);
    setSlideIndex(0); // Reset slide index to start from the first slide
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedProject(null);
  };

  const nextSlide = () => {
    setSlideIndex((prevIndex) =>
      prevIndex === selectedProject.slides.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevSlide = () => {
    setSlideIndex((prevIndex) =>
      prevIndex === 0 ? selectedProject.slides.length - 1 : prevIndex - 1
    );
  };

  
  return (
    <Wrapper id="projects">
      <div className="whiteBg" style={{marginRight:9}}>
        <div className="container" >
          <HeaderInfo>
            <h1 className="font40 extraBold">Our Awesome Projects</h1>
            <p className="font13" style={{marginLeft:"9px"}}>
              RapportSoft offers innovative software solutions designed to
              streamline operations and enhance productivity. From Empty Yard
              Management Systems to BondedWare House solutions, we deliver
              tailored technology to meet your business needs.
            </p>
          </HeaderInfo>
          {/* <div className="row textCenter"> */}
          <ProjectRow className="row textCenter" style={{marginLeft:45}} >
            {projects.map((project, index) => (
              <div
              key={index}
              className="col-xs-12 col-sm-4 col-md-4 col-lg-4"
              
            >
                <ProjectBox 
                
                  img={project.img}
                  
                  title={project.title}
                  text={project.text}
                  action={() => handleProjectClick(project)}
                />
              </div>
            ))}
            </ProjectRow>
          {/* </div> */}
        </div>
      </div>

      {showModal && selectedProject && (
        <Modal >
          <ModalContent>
            <CloseButton onClick={closeModal}>×</CloseButton>
            <ModalHeader>{selectedProject.title}</ModalHeader>
            <ModalBody>
              <SlideTitle>{selectedProject.slides[slideIndex].title}</SlideTitle>
              <SlideImage style={{height:350,width:430}} src={selectedProject.slides[slideIndex].img} alt="Slide" />
              <p style={{marginLeft:"9px"}}>{selectedProject.slides[slideIndex].text}</p>
            </ModalBody>
            {/* <ModalFooter> */}
            <StyledModalFooter>
              <FullButton title="Prev" action={prevSlide} />
              <FullButton title="Next" action={nextSlide} />
              <FullButton title="Close" action={closeModal} />
              </StyledModalFooter>
            {/* </ModalFooter> */}
          </ModalContent>
        </Modal>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;

const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;


const Modal = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
`;

const ModalContent = styled.div`
  background-color: #fefefe;
  padding: 20px;
  width: 80%;
  max-width: 600px;
  border-radius: 8px;
  position: relative;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 24px;
`;

const ModalHeader = styled.h2`
  font-size: 24px;
  font-weight: bold;
  margin-top: 0;
`;

const ModalBody = styled.div`
  text-align: center;
  p {
    margin-top: 10px;
  }
`;

const SlideTitle = styled.h3`
  font-size: 18px;
  margin-top: 15px;
  font-weight: bold;
`;

const SlideImage = styled.img`
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  margin-top: 10px;
`;

const ModalFooter = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
`;

const StyledModalFooter = styled(ModalFooter)`
  display: flex;
  justify-content: space-between; /* Spread buttons evenly */
  padding: 20px; /* Adjust padding as needed */
  
  button {
    margin: 0 10px; /* Adjust margin to create space between buttons */
  }
`;
const ProjectRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
`;
export { Wrapper, HeaderInfo };
