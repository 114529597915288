import React from "react";
// Sections
import TopNavbar from "../components/Nav/TopNavbar";
import Header from "../components/Sections/Header";
import Services from "../components/Sections/Services";
import Projects from "../components/Sections/Projects";
import Blog from "../components/Sections/Blog";
import Pricing from "../components/Sections/Pricing";
import Contact from "../components/Sections/Contact";
import Footer from "../components/Sections/Footer"
import Login from "../components/Sections/Login";
import { useLocation } from "react-router-dom";

export default function Landing() {
  const location = useLocation();
  const status = location.state?.status;
  console.log('status status ',status);
  return (
    <>
      <TopNavbar />
      <Header />
      <Services />
      <Projects />
      <Blog />
      <Pricing status={status} />
      {/* <Contact /> */}
      <Footer />
    </>
  );
}


